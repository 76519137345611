import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button,
  Grid
} from '@material-ui/core';
import Timer from 'react-compound-timer'
import Page from 'src/components/Page';
import { publicServices } from '../../services/publicServices';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CodeSent = () => {
  const classes = useStyles();
  const { email } = useParams();
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [secondsDef] = useState(5);
  const [seconds, setSeconds] = useState(secondsDef);
  const [emailsSent, setEmailsSent] = useState(2);

  function Counter(props){
    let time = props.timer;
    if (time>0) {
      return (
        <Timer
          initialTime={props.timer*1000}
          startImmediately={true}
          lastUnit="s"
          direction="backward"
          checkpoints={[
            {
              time: 0,
              callback: () => {setShow(true); setSeconds(0);},
            },
          ]}
        >
          <Timer.Seconds/>
        </Timer>
      );
    }else{
      return('');
    }
  }

  function resendEmail(){
    setDisabled(true);
    publicServices.resendActivation(email)
      .then(data_result => {
        if (data_result.status===200) {
          setEmailsSent(emailsSent+1);
          setShow(false);
          setSeconds(secondsDef*emailsSent);
        }
      })
      .catch(function (error) {
      }).then(function () {
        setDisabled(false);
      });
  }

  function ButtonCond(props){
    let show=props.propShow;
    if (show) {
      return(
        <Button variant="outlined" color="primary" disabled={disabled} onClick={() => { resendEmail(); }}>
            Reenviar codigo
        </Button>
      );
    }else{
      return(
        <Button variant="outlined" color="primary" disabled="true">
            Reenviar codigo
        </Button>
      );
    }
  }

  return (
    <Page
      className={classes.root}
      title="Email enviado"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          {emailsSent===2 ?
            <Typography variant="h2" component="h2" className="text-center">Te hemos enviado un email de verificación</Typography>
          :
            <Typography variant="h2" component="h2" className="text-center">Te hemos reenviado el email de verificación</Typography>
          }
          <br />
          {emailsSent < 5 ?
            <React.Fragment>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
              {seconds!==0 ? 
                <Typography variant="body2" component="span" className="text-center">Aun no recibes el email? Puedes solicitar otro en <Counter timer={seconds}/> segundos</Typography>
              : 
                <Typography variant="body2" component="span" className="text-center">Aun no recibes el email? Solicita otro ahora mismo</Typography>
              }
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <ButtonCond propShow={show}/>
              </Grid>
            </React.Fragment>
          :''}
        </Container>
      </Box>
    </Page>
  );
};

export default CodeSent;

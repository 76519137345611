import React, { useState, useEffect } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import {
  Row,
  Col,
  Button
} from "reactstrap";
import { publicServices } from '../../services/publicServices';
import image_default from '../../icons/image_default.svg';
import plus from '../../icons/plus.svg';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const LastAdds = () => {

  const [tipos, setTipos] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  useEffect(() => {
    getAllTipos();
  }, []);

  function getAllTipos(){
    publicServices.getTipos()
    .then(response => {
      if (response.status===200) {
        console.log('response.data.data==>');
        console.log(response.data.data);
        setTipos(response.data.data);
      }
    })
    .catch(function (error) {
        return error;
    })
  }

  function CardTipos(props){
    let tipos_ = props.propTipos;
    var items = '';
    if (tipos_!==null && tipos_.length>0) {
      items = tipos_.map(tipo_ => (
        <div key={tipo_.id} className="item_slide_type">
          <div>
            <Row className="justify-content-center align-items-center w-100">
              <Col xs="12">
                <span className="enconde_sans">{tipo_.nombre}</span>
                <Button outline color="theme" to={"/espacios-publicitarios/"+tipo_.id} component={RouterLink} tag={RouterLink}>Ver más</Button>
              </Col>
            </Row>
            <img src={tipo_.imagen} alt=""/>
          </div>
        </div>
      ));
    }
    return(
      <React.Fragment>
          <Col xs="12">
            <Slider {...settings}>
              {items}
            </Slider>
          </Col>
      </React.Fragment>
    );
  }

  return (
      <CardTipos propTipos={tipos}/>
  );
};

export default LastAdds;

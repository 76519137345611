import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { 
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import topHeader from '../../icons/imgs/topHeader.jpg';

const TerminosCondiciones = () => {

  useEffect(() => {
     document.title = "Anunciotes - Terminos y condiciones"
  }, []);

  return (
      <React.Fragment>

        <Container fluid={true} className="topM" style={{backgroundImage: `url(${topHeader})`}}>
          <div className="overlay"></div>
          <Container className="pt-5 pb-3">
            <Row>
              <Col xs="12" className="text-left">
                <h2 className="fw_600 light">Terminos y condiciones</h2>
              </Col>
              <Col xs="12" className="text-left">
                <Breadcrumb tag="nav" listTag="div" className="cs_01">
                  <BreadcrumbItem to="/" component={RouterLink} tag={RouterLink}>
                    <i className="fa fa-home" aria-hidden="true"></i>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Terminos y condiciones</BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container className="container-lg py-7">
          <Row className="justify-content-center">
            <Col xs="12" md="10" lg="8" xl="7">
              <Row>
                <Col xs="12" className="text-center">
                  <h2>Términos y Condiciones de Uso de Aplicacipon Web</h2>  
                </Col>
                <Col xs="12">
                  <p>Términos y condiciones de uso de Aplicacipon web</p>
                  <h3>1. ACEPTACIÓN</h3>
                  <p>En el presente documento (en adelante, el “Contrato”) se establecen los términos y condiciones de Anunciotes.com con domicilio en Av. Pie de la Cuesta 1709, Los Robles, Santiago de Querétaro, Qro, que serán de aplicación al acceso y uso por parte del Usuario de esta sistema web (el  Sistema Web”). Les rogamos lean atentamente el presente Contrato.</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

      </React.Fragment>
  );
};

export default TerminosCondiciones;

import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Container
} from '@material-ui/core';
import Page from 'src/components/Page';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { sellerServices } from '../../../services/sellerServices';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Vendedor = ({ className, ...rest }) => {

  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  const [values, setValues] = useState({
    firstName: 'Katarina',
    lastName: 'Smith',
    email: 'demo@devias.io',
    phone: '',
    state: 'Alabama',
    country: 'USA'
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Page
      className={classes.root}
      title="Account"
    >
      <Container maxWidth={false}>
        <Grid
          container
        >
          <Grid
            item
            xs={12}
          >
            <Box mt={3}>
              <Card
                className={clsx(classes.root, className)}
                {...rest}
              >
                <Formik
                  initialValues={{
                    empresa: '',
                    telefono: '',
                    whatsapp: '',
                    web: ''
                  }}
                  validationSchema={Yup.object().shape({
                    empresa: Yup.string().max(100),
                    telefono: Yup.string().max(15),
                    whatsapp: Yup.string().max(100).required('El whatsapp es requerido'),
                    web: Yup.string().max(150)
                  })}
                  onSubmit={(values) => {
                    setDisabled(true);
                    sellerServices.completarVendedor(values)
                      .then(response => {
                        if (response.status === 201) {
                          navigate('/vendor/dashboard/', { replace: true });
                        }
                      })
                      .catch(function (error) {
                      }).then(function () {
                        setDisabled(false);
                      });
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values
                  }) => (
                    <form
                      autoComplete="off"
                      noValidate
                      onSubmit={handleSubmit}
                      className={clsx(classes.root, className)}
                      {...rest}
                    >
                      <Card>
                        <CardHeader
                          subheader="Completa la información para continuar"
                          title="Sobre tu negocio"
                        />
                        <Divider />
                        <CardContent>
                          <Grid
                            item
                            xl={6}
                            lg={8}
                            md={10}
                            sm={12}
                            xs={12}
                          >
                            <Grid
                              container
                              spacing={3}
                            >
                              <Grid
                                item
                                md={6}
                                xs={12}
                              >
                                <TextField
                                  error={Boolean(touched.empresa && errors.empresa)}
                                  fullWidth
                                  helperText={touched.empresa && errors.empresa}
                                  label="Empresa"
                                  margin="normal"
                                  name="empresa"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.empresa}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid
                                item
                                md={6}
                                xs={12}
                              >
                                <TextField
                                  error={Boolean(touched.telefono && errors.telefono)}
                                  fullWidth
                                  helperText={touched.telefono && errors.telefono}
                                  label="Telefono"
                                  margin="normal"
                                  name="telefono"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.telefono}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid
                                item
                                md={6}
                                xs={12}
                              >
                                <TextField
                                  error={Boolean(touched.whatsapp && errors.whatsapp)}
                                  fullWidth
                                  helperText={touched.whatsapp && errors.whatsapp}
                                  label="Whatsapp"
                                  margin="normal"
                                  name="whatsapp"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.whatsapp}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid
                                item
                                md={6}
                                xs={12}
                              >
                                <TextField
                                  error={Boolean(touched.web && errors.web)}
                                  fullWidth
                                  helperText={touched.web && errors.web}
                                  label="Pagina web"
                                  margin="normal"
                                  name="web"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.web}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          p={2}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={disabled}
                          >
                            Save details
                          </Button>
                        </Box>
                      </Card>
                    </form>
                  )}
                </Formik>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

Vendedor.propTypes = {
  className: PropTypes.string
};

export default Vendedor;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Container,
  Row, 
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  List
} from 'reactstrap';
import topHeader from '../../icons/imgs/topHeader.jpg';
import heigthImg from '../../icons/height.svg';
import widthImg from '../../icons/width.svg';
import targetImg from '../../icons/target.svg';
import location from '../../icons/location.svg';
import { publicServices } from '../../services/publicServices';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import image_default from '../../icons/image_default.svg';
import { Link as RouterLink } from 'react-router-dom';
import { CheckCircle } from 'react-feather';
import Disponibilidad from './Disponibilidad';

import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

const AnuncioPublic = () => {

  const { id } = useParams();
  const [add, setAdd] = useState(null);
  const [specs, setSpecs] = useState(null);
  const [imgs, setImgs] = useState(null);
  const [showDis, setShowDis] = useState(false);

  function setDisponibilidad(){
      setShowDis(true);
      scroller.scrollTo('disponibilidad_', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
  }

  useEffect(() => {
      getAdd();
      getSpecsByAdd();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getAdd(){
      publicServices.getAdd(id)
      .then(response => {
        if (response.status===200) {
          setAdd(response.data.data);
          var dev_i = [];
          var c=0;
          response.data.data.imagenes.forEach(element => {
            dev_i[c]={
              original: element.url,
              thumbnail: element.url_thumb
            }
            c++;
          });
          setImgs(dev_i);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  function getSpecsByAdd(){
      publicServices.getSpecsByAdd(id)
      .then(response => {
        if (response.status===200) {
          setSpecs(response.data.data);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  function Gallery(props){
    if (props.propImgs!==null && props.propImgs.length>0) {
      return(<ImageGallery items={props.propImgs} />);
    }
    return(
      <Row className="justify-content-center align-items-center w-100 m-0 py-2">
        <Col xs="auto">
          <img src={image_default} style={{width: '200px', maxWidth: '100%'}} alt={add.titulo}/>
        </Col>
        <Col xs="12" className="text-center">
          <span>No hay imagenes disponibles</span>
        </Col>
      </Row>
    );
  }

  function ListSpecs(props){
    console.log('props.propSpecs==>');
    console.log(props.propSpecs);
    let specs_ = props.propSpecs;
    if (specs_!==null && specs_.length>0) {
      const listItems = specs_.map((spec) => {
        return (
          <li>
            <CheckCircle/>
            {spec.nombre}
          </li>
        )
      });
      return(
        <React.Fragment>
          <Col xs="12" className="pt-3 pb-0 p-0">
            <h5 className="fw_600 text-secondary">Caracteristicas</h5>
          </Col>  
          <Col xs="12" className="py-3 p-0">
            <List type="unstyled" className="listSpecs">
              {listItems}
            </List>
          </Col>
        </React.Fragment>
      );
    }
    return('');
  }

  return (
      <React.Fragment>

        <Container fluid={true} className="topM" style={{backgroundImage: `url(${topHeader})`}}>
          <div className="overlay"></div>
          {add!==null ? 
            <Container className="pt-5 pb-3">
              <Row>
                <Col xs="12" className="text-left">
                  <h2 className="fw_600 light">{add.titulo}</h2>
                </Col>
                <Col xs="12" className="text-left">
                  <Breadcrumb tag="nav" listTag="div" className="cs_01">
                    <BreadcrumbItem tag="a" href="#">
                      <i className="fa fa-home" aria-hidden="true"></i>
                    </BreadcrumbItem>
                    <BreadcrumbItem to="/espacios-publicitarios" component={RouterLink} tag={RouterLink}>Catalogo</BreadcrumbItem>
                    <BreadcrumbItem to={"/espacios-publicitarios/"+add.tipo_anuncio.id} component={RouterLink} tag={RouterLink}>{add.tipo_anuncio.nombre}</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="#">{add.subtipo_anuncio.nombre}</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{add.titulo}</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          : ''}
        </Container>
        <Container className="pt-5 pb-6">
          {add!==null ? 
            <Row>
              <Col xs="12" md="7" lg="7">
                <Gallery propImgs={imgs}/>
                <Row className="justify-content-center w-100 m-0">
                  <Col xs="12" className="py-2 text-justify p-0">
                    {add.descripcion_larga}
                  </Col>
                </Row>
                <Row className="justify-content-center w-100 m-0">
                  <Col xs="12" className="pt-3 pb-0 p-0">
                    <h5 className="fw_600 text-secondary">Especificaciones</h5>
                  </Col>  
                  <Col xs="12" className="py-3 p-0">
                      <div className="inline_icons">
                        <div>
                          <div>
                            <img src={widthImg} alt={add.titulo}/>
                          </div>
                          <span>
                            <strong className="block_ctrl">Ancho</strong>  
                            {parseFloat(add.ancho)}
                          </span>
                        </div>
                        <div>
                          <div>
                            <img src={heigthImg} alt={add.titulo}/>
                          </div>
                          <span>
                            <strong className="block_ctrl">Alto</strong>  
                            {parseFloat(add.largo)}
                          </span>
                        </div>
                        <div>
                          <div>
                            <img src={targetImg} alt={add.titulo}/>
                          </div>
                          <span>
                            <strong className="block_ctrl">Target</strong>  
                            {add.target}
                          </span>
                        </div>
                      </div>
                  </Col>
                  <ListSpecs propSpecs={specs} />
                </Row>
              </Col>
              <Col xs="12" md="5" lg="5">
                {add.vendedor_wp!==null ? 
                  <Row className="justify-content-center w-100 m-0">
                    <Col xs="auto" className="pb-3">
                      <Button
                        color="whatsapp"
                        href={'https://wa.me/52'+add.vendedor_wp+'?text=Me%20interesa%20el%20espacio%20publicitario%20que%20ofrece%20en%20www.billboardzone.com'}
                        target="_blank"
                      >
                        <i class="fa fa-whatsapp" aria-hidden="true"></i>{' '}
                        CONTACTA AL DUEÑO
                      </Button>
                    </Col>
                  </Row>
                :''}
                <iframe
                  src={'https://maps.google.com/maps?q='+add.latitud+','+add.longitud+'&z=15&output=embed'}
                  width="100%"
                  height="575"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen="true"
                  aria-hidden="false"
                  tabIndex="0"
                  title={add.calle+' #'+add.numero+', '+add.colonia+'. '+add.estado.nombre+', '+add.municipio.nombre}
                />
                <Row className="justify-content-center w-100 m-0">

                  <Col xs="12" className="py-3 p-0">
                      <div className="inline_icons">
                        <div className="w-100">
                          <div>
                            <img src={location} alt={add.titulo} />
                          </div>
                          {add.calle!==null && add.calle!=='' ?
                            <span>
                              {add.calle} #{add.numero}, {add.colonia}. {add.estado.nombre}, {add.municipio.nombre}
                            </span>
                          :
                            <span>
                              {add.carretera}, {add.kilometro!==null ? 'Km '+add.kilometro+',' :''} {add.estado.nombre}, {add.municipio.nombre}
                            </span>
                          }
                        </div>
                      </div>
                  </Col>

                  <Col xs="auto" className="p-3 px-0">
                    <Button
                      outline
                      color="primary"
                      href={'https://maps.google.com/maps?q='+add.latitud+','+add.longitud+'&z=15'}
                      target="_blank"
                    >
                      <i class="fa fa-map-marker" aria-hidden="true"></i>{' '}
                      VER EN GOOGLE MAPS
                    </Button>
                  </Col>
                  <Col xs="auto" className="p-0">
                    <Button
                      outline
                      color="secondary"
                      onClick={(e) => {setDisponibilidad()}}
                    >
                      <i class="fa fa-calendar-o" aria-hidden="true"></i>{' '}
                      CONSULTAR DISPONIBILIDAD
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" id="disponibilidad_">
              {showDis ?
                <Row className="justify-content-center w-100 m-0">
                    <Col xs="12" className="pt-3 pb-0 p-0">
                      <h5 className="fw_600 text-secondary">Disponibilidad</h5>
                    </Col>
                    <Col xs="12">
                      <Disponibilidad propAdd={add} propSetAdd={setAdd}/>
                    </Col>
                </Row>
              :''}
              </Col>
            </Row>
          : ''}
        </Container>

      </React.Fragment>
  );
};

export default AnuncioPublic;

import React, { useState, useEffect } from 'react';
import { publicServices } from '../../services/publicServices';
import { sellerServices } from '../../services/sellerServices';
import { Link as RouterLink } from 'react-router-dom';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
import marker_1 from '../../icons/marker_1.svg';
import marker_2 from '../../icons/marker_2.svg';
import marker_3 from '../../icons/marker_3.svg';
import marker_4 from '../../icons/marker_4.svg';
import marker_5 from '../../icons/marker_5.svg';
import marker_6 from '../../icons/marker_6.svg';
import marker_7 from '../../icons/marker_7.svg';
import image_default from '../../icons/image_default.svg';
import heigthImg from '../../icons/height.svg';
import widthImg from '../../icons/width.svg';
import targetImg from '../../icons/target.svg';
import location from '../../icons/location.svg';
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

const MapWithCluster = withScriptjs(withGoogleMap(props => {

  const [adds, setAdds] = useState(null);
  const [add, setAdd] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
      getAdds();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getAdds(){
    var service;
    if (props.public) {
      service = publicServices;
    }else{
      service = sellerServices;
    }
    service.getAdds(1,1000)
    .then(response => {
      if (response.status===200) {
        setAdds(response.data.data);
      }
    })
    .catch(function (error) {
        return error;
    })
  }

  function onMarkerClick(add_){
    console.log('add_==>');
    console.log(add_);
    setAdd(add_);
    setModal(true);
  }

  return(
    <React.Fragment>
    {adds!==null ? 
      <GoogleMap
        defaultZoom={props.zoom}
        defaultCenter={{ lat: 24, lng: -100.4059463 }}
      >
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          gridSize={60}
        >
          {adds.map((add) => 
            {
              var icon='';
              switch (add.tipo_anuncio.id) {
                case 1:
                  icon=marker_1;
                  break;
                case 2:
                  icon=marker_2;
                  break;
                case 3:
                  icon=marker_3;
                  break;
                case 4:
                  icon=marker_4;
                  break;
                case 5:
                  icon=marker_5;
                  break;
                case 6:
                  icon=marker_6;
                  break;
                case 7:
                  icon=marker_7;
                  break;
                default:
                  icon=marker_1;
                  break;
              }
              return(
                <Marker
                  key={add.id}
                  position={{ lat: parseFloat(add.latitud), lng: parseFloat(add.longitud) }}
                  onClick={ (e) => { onMarkerClick(add) } }
                  icon={
                    {
                      url: icon,
                      scaledSize: new window.google.maps.Size(50, 65)
                    }
                  }
                />
              );
            }
          )}
        </MarkerClusterer>
      </GoogleMap>
    :''}
    <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered detail_add">
      {add!==null?
        <React.Fragment>
          <ModalHeader toggle={toggle}>{add.titulo}</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="12" className="text-center">
                <img src={add.imagenes.length>0?add.imagenes[0].url_thumb:image_default}   alt="" className="img-fluid"/>
              </Col>
              <Col xs="12" className="py-3 p-0 text-justify">
                <span>{add.descripcion_corta}</span>
              </Col>
              <Col xs="12" className="py-3 p-0">
                  <div className="inline_icons">
                    <div>
                      <div>
                        <img src={widthImg} alt={add.titulo}/>
                      </div>
                      <span>
                        <strong className="block_ctrl">Ancho</strong>  
                        {parseFloat(add.ancho)}
                      </span>
                    </div>
                    <div>
                      <div>
                        <img src={heigthImg} alt={add.titulo}/>
                      </div>
                      <span>
                        <strong className="block_ctrl">Largo</strong>  
                        {parseFloat(add.largo)}
                      </span>
                    </div>
                    <div>
                      <div>
                        <img src={targetImg} alt={add.titulo}/>
                      </div>
                      <span>
                        <strong className="block_ctrl">Target</strong>  
                        {add.target}
                      </span>
                    </div>
                  </div>
              </Col>
              <Col xs="12" className="py-3 p-0">
                  <div className="inline_icons">
                    <div className="w-100">
                      <div>
                        <img src={location} alt={add.titulo}/>
                      </div>
                      <span>
                        {add.calle} #{add.numero}, {add.colonia}. {add.estado.nombre}, {add.municipio.nombre}
                      </span>
                    </div>
                  </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle} component={RouterLink} tag={RouterLink} to={"/anuncio/"+add.id+"/"}>
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              {' '}Ver mas
            </Button>
          </ModalFooter>
        </React.Fragment>
      :''}
    </Modal>
    </React.Fragment>
  );

}));

export default MapWithCluster;
import React from 'react';
import logo_white from '../icons/logo_white.svg';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src={logo_white}
      {...props}
    />
  );
};

export default Logo;

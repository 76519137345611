import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Paper
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { sellerServices } from '../../services/sellerServices';
import { X as XIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    maxHeight: '65vh',
    minHeight: '40vh',
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}


const Caracteristicas = (props) => {

  const classes = useStyles();

  const add = props.propAdd;

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [counter, setCounter] = React.useState(0);

  useEffect(() => {
    getFreeSpecs();
    getSpecsAssigned();
  }, [add]);

  useEffect(() => {
    console.log('counter==>');
    console.log(counter);
    if (counter>0) {
      saveSpecAssigned();
    }
  }, [right,counter]);

  function getFreeSpecs(){
      sellerServices.getFreeSpecs(add.tipo_anuncio.id, add.id)
      .then(response => {
          if (response.status===200) {
              setLeft(response.data.data);
          }
      })
      .catch(function (error) {
          return error;
      })
  }

  function getSpecsAssigned(){
      sellerServices.getSpecsAssigned(add.id)
      .then(response => {
          if (response.status===200) {
              setRight(response.data.data);
          }
      })
      .catch(function (error) {
          return error;
      })
  }

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
  };

  const handleAllRight = () => {
      setRight(right.concat(left));
      setLeft([]);
      setCounter(counter+1);
  };

  const handleCheckedRight = () => {
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
      setLeft(left.concat(right));
      setRight([]);
      setCounter(counter+1);
  };

  function saveSpecAssigned(){
      sellerServices.addSpecAssigned(right, add.id)
      .then(response => {
      })
      .catch(function (error) {
          return error;
      })
  }


  const customList = (items) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.nombre} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <React.Fragment>
      {add!==null ?
              <Grid 
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Caracteristicas
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Por agregar
                  </Typography>
                  {customList(left)}
                </Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleAllRight}
                      disabled={left.length === 0}
                      aria-label="move all right"
                    >
                      ≫
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleAllLeft}
                      disabled={right.length === 0}
                      aria-label="move all left"
                    >
                      ≪
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Agregadas
                  </Typography>
                  {customList(right)}
                </Grid>
              </Grid>
      :''}
    </React.Fragment>
  );
};

export default Caracteristicas;
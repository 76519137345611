import React, { useState, useEffect } from 'react';

import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import { publicServices } from '../../services/publicServices';
import Espectaculares from '../../icons/Espectaculares.svg';
import Transporte_publico from '../../icons/Transporte_publico.svg';
import Centros_comerciales from '../../icons/Centros_comerciales.svg';
import { Link as RouterLink } from 'react-router-dom';

const ItemsMenu = (props) => {

  const [tipos, setTipos] = useState(null);

  useEffect(() => {
      getTipos();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getTipos(){
      publicServices.getTiposContentAnuncio()
      .then(response => {
        if (response.status===200) {
          setTipos(response.data.data);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  function Options(props){
      var tipos = props.propTipos;
      if (tipos!==null && tipos.length>0){
        const listItems = tipos.map((tipo) => {
          return (
            <Media
              className="d-flex align-items-center"
              to={'/espacios-publicitarios/'+tipo.id} tag={RouterLink}
              key={tipo.id}
            >
              <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                <img src={tipo.icono} alt={tipo.nombre}/>
              </div>
              <Media body className="ml-3">
                <h6 className="heading text-primary mb-md-1">
                  {tipo.nombre}
                </h6>
              </Media>
            </Media>
            )
        });
        return(listItems);
      }
      return('');
  }


  return (
    <React.Fragment>
      <DropdownToggle nav to="/espacios-publicitarios" tag={RouterLink}>
        <i className="ni ni-ui-04 d-lg-none mr-1" />
        <span className="nav-link-inner--text">ESPACIOS PUBLICITARIOS</span>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-lg">
        <div className="dropdown-menu-inner">
          <Options propTipos={tipos} />
        </div>
      </DropdownMenu>
    </React.Fragment>
  );
};

export default ItemsMenu;
import React, { useState } from 'react';
import {
  Box,
  Button,
  CardContent,
  Divider,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
  Typography
} from '@material-ui/core';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import { sellerServices } from '../../services/sellerServices';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MapWithAMarker from '../ui/Map'

const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY_MAPS}&v=3.exp&libraries=geometry,drawing,places`;

Yup.setLocale({
  mixed: {
    required: 'Esta campo es requerido',
  },
  string: {
    required: 'Esta campo es requerido',
    max: 'El texto es demasiado largo',
    min: 'El texto es demasiado corto',
  },
  number: {
    integer: 'Solo se aceptan datos numericos'
  }
})

const Ubicacion = (props) => {

  const add = props.propAdd;
  const setAdd = props.propSetAdd;
  const setActiveTab = props.propSetActiveTab;

  const [open, setOpen] = useState(false);
  

  function Map(props){
    let latitud = props.propAdd.latitud;
    let longitud = props.propAdd.longitud;
    let tipo_anuncio_id = props.propAdd.tipo_anuncio.id;
    console.log('tipo_anuncio_id==>');
    console.log(tipo_anuncio_id);
    if (latitud!=='' && longitud!=='') {
      return(
        <MapWithAMarker
          googleMapURL={mapUrl}
          loadingElement={<span>Cargando</span>}
          containerElement={<div style={{ height: '650px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          latitud={parseFloat(latitud)}
          longitud={parseFloat(longitud)}
          iconType={tipo_anuncio_id}
          zoom={16}
        />
      );
    }else{
      return('');
    }
  }

  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            color="textSecondary"
            variant="body1"
          >
            Ubicación en el mapa
          </Typography>
          <Divider />
        </Grid>
        <Grid
          xs={12}
        >
          <Map propAdd={add}/>
        </Grid>
        <Divider/>
        <Grid
          xs={12}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            pt={2}
          >
            <Button
              variant="contained"
              color="primary"
              endIcon={<GpsFixedIcon/>}
              onClick={(e) => {setOpen(true)}}
            >
              Modificar coordenadas
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ingresa las coordenadas.</DialogTitle>
        <Formik
          initialValues={{
            latitud: add.latitud,
            longitud: add.longitud
          }}
          validationSchema={Yup.object().shape({
            latitud: Yup.string().max(150).required(),
            longitud: Yup.string().max(150).required()
          })}
          onSubmit={(values) => {
            sellerServices.updateAdd(values,add.id)
            .then(response => {
              if (response.status===200) {
                setAdd(response.data.data);
                setActiveTab(1);
              }
                console.log(response);
            })
            .catch(function (error) {
                return error;
            })
            .then(response => {
                setOpen(false);
            })
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              autoComplete="off"
              onSubmit={(e) => { e.preventDefault(); handleSubmit(e)}}
            >
              <DialogContent>
                <DialogContentText>
                  No sabes como obtener las coordanadas correctas? Ve un videotutorial {' '}
                  <Link href="https://youtu.be/UHRNeqsdgrI" target="_blank" rel="noreferrer">
                    AQUI
                  </Link>
                </DialogContentText>
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Latitud"
                          name="latitud"
                          onChange={(e) => {handleChange(e)}}
                          required
                          error={Boolean(touched.latitud && errors.latitud)}
                          helperText={touched.latitud && errors.latitud}
                          onBlur={handleBlur}
                          value={values.latitud}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Longitud"
                          name="longitud"
                          onChange={(e) => {handleChange(e)}}
                          required
                          error={Boolean(touched.longitud && errors.longitud)}
                          helperText={touched.longitud && errors.longitud}
                          onBlur={handleBlur}
                          value={values.longitud}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
              </DialogContent>
              <DialogActions>
                <Button onClick={(e) => {setOpen(false)}} color="primary">
                  Cancelar
                </Button>
                <Button type="submit" color="primary">
                  Guardar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
};

export default Ubicacion;

import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import { authServices } from '../../services/authServices';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  
  const classes = useStyles();
  const navigate = useNavigate();
  
  const [disable, setDisable] = useState(false);

  const responseGoogle = (response) => {
    console.log('response==>');
    console.log(response);
    var res = response.profileObj;
    setDisable(true);
    authServices.googleLogin(res.googleId)
      .then(response => {
        actionsLogin(response);
      })
      .catch(function (error) {
      }).then(function () {
        setDisable(false);
      });
  }

  const responseFacebook = (response) => {
    setDisable(true);
    authServices.facebookLogin(response.userID)
      .then(response => {
        actionsLogin(response);
      })
      .catch(function (error) {
      }).then(function () {
        setDisable(false);
      });
  }

  const actionsLogin = (response) => {
    if (response.status === 201) {
      if (response.data.user.type==='seller') {
        if (response.data.user.vendedor===null) {
          navigate('/vendor/completa-tu-perfil', { replace: true });
        }else{
          navigate('/vendor/anuncios', { replace: true }); 
        }
      }else if(response.data.user.type==='cliente'){
        navigate('/cliente/dashboard', { replace: true }); 
      }else if(response.data.user.type==='admin'){
        navigate('/a/dashboard', { replace: true }); 
      }
    }
  }

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Ingresa un email valido').max(255).required('El email es requerido'),
              password: Yup.string().max(255).required('La contraseña es requerida')
            })}
            onSubmit={(values) => {
              setDisable(true);
              authServices.login(values)
                .then(response => {
                  actionsLogin(response);
                })
                .catch(function (error) {
                }).then(function () {
                  setDisable(false);
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Inicia sesión
                  </Typography>
                </Box>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <GoogleLogin
                      clientId="458448170505-5nbcnkiebdvnmk1kf01m0ubt1r8vfkaf.apps.googleusercontent.com"
                      render={renderProps => (
                        <Button
                          fullWidth
                          startIcon={<GoogleIcon />}
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          size="large"
                          variant="contained"
                        >
                          Ingresa con Google
                        </Button>
                      )}
                      buttonText="Ingresa con Google"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      isSignedIn={false}
                      cookiePolicy={'single_host_origin'}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <FacebookLogin
                      appId="2002289936584627"
                      autoLoad={false}
                      callback={responseFacebook}
                      render={renderProps => (
                        <Button
                          color="primary"
                          fullWidth
                          startIcon={<FacebookIcon />}
                          onClick={renderProps.onClick}
                          size="large"
                          variant="contained"
                        >
                          Ingresa con Facebook
                        </Button>
                      )}
                    />
                  </Grid>
                </Grid>
                <Box
                  mt={3}
                  mb={1}
                >
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    o ingresa con tu email
                  </Typography>
                </Box>
                <TextField
                  autoFocus={true}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Contraseña"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={disable}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    ENTRAR
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="center"
                >
                  <Link
                    component={RouterLink}
                    to="/olvide-mi-contrasena"
                    variant="h6"
                  >
                    ¿Has olvidado la contraseña?
                  </Link>
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="center"
                >
                  ¿Aun no tienes una cuenta?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/registro"
                    variant="h6"
                  >
                    Registrate aqui
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;

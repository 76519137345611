import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import { sellerServices } from '../../services/sellerServices';
import { Formik } from 'formik';
import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'Esta campo es requerido',
  },
  string: {
    required: 'Esta campo es requerido',
    max: 'El texto es demasiado largo',
    min: 'El texto es demasiado corto',
  },
  number: {
    integer: 'Solo se aceptan datos numericos'
  }
})

const Acciones = (props) => {

  const add = props.propAdd;
  const setAdd = props.propSetAdd;
  const navigate = useNavigate();

  useEffect(() => {
    if (add!==null) {

    }
  }, [add]);

  const [show, setShow] = useState(add.show);
  const [open, setOpen] = useState(false);

  const updateShow = (newvalue) => {
    var temp_data = {
        show: newvalue
    }
    sellerServices.updateAdd(temp_data,add.id)
    .then(response => {
        setAdd(response.data.data);
    })
    .catch(function (error) {
        return error;
    })
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {add!==null ?
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <Typography
                color="textSecondary"
                variant="body1"
              >
                Estatus
              </Typography>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FormControlLabel
                control={<Switch checked={!!show} onChange={(e) => {setShow(!show);updateShow(!show); }} name="status" />}
                label={show ? 'ACTIVO' : 'INACTIVO'}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography
                color="textSecondary"
                variant="body1"
              >
                Borrado
              </Typography>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<DeleteIcon />}
                onClick={handleClickOpen}
              >
                Borrar anuncio
              </Button>
            </Grid>
          </Grid>
      :''}

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Formik
          initialValues={{
            phrase: ''
          }}
          validationSchema={Yup.object().shape({
            phrase: Yup.string().required().test('test-name', 'El texto es incorrecto', 
            function(value) {
                if (value==='eliminar de forma permanente') {
                  return true;
                }else{
                  return false;
                }
            })
          })}
          onSubmit={(values) => {
            sellerServices.deleteAdd(add.id)
            .then(response => {
              if (response.status===204) {
                navigate('/vendor/anuncios/', { replace: true });
              }
            })
            .catch(function (error) {
                return error;
            })
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              autoComplete="off"
              onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}
              style={{
                display: 'inline-block',
                width: '100%',
              }}
            >
              <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Para confirmar la eliminación, escriba <em>eliminar de forma permanente</em> en el campo de texto.
                </DialogContentText>
                <TextField
                  fullWidth
                  name="phrase"
                  onChange={(e) => {handleChange(e); }}
                  required
                  error={Boolean(touched.phrase && errors.phrase)}
                  helperText={touched.phrase && errors.phrase}
                  onBlur={handleBlur}
                  value={values.phrase}
                  variant="outlined"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="default">
                  Cancelar
                </Button>
                <Button type="submit" color="primary">
                  Confirmar borrado
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>

      </Dialog>
    </React.Fragment>
  );
};

export default Acciones;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { sellerServices } from '../../services/sellerServices';
import {
  Container,
  makeStyles,
  Card,
  CardHeader,
  Grid,
  Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import InformacionBasica from './InformacionBasica';
import Ubicacion from './Ubicacion';
import ImageManager from './ImageManager';
import ImageManagerTwo from './ImageManagerTwo';
import Caracteristicas from './Caracteristicas';
import Acciones from './Acciones';
import Comodin from './Comodin';
import Disponibilidad from './Disponibilidad';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Anuncio = () => {

  const classes = useStyles();

  const { id } = useParams()
  const [add, setAdd] = useState(null);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
      getAdd();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getAdd(){
      sellerServices.getAdd(id)
      .then(response => {
        if (response.status===200) {
          setAdd(response.data.data);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  return (
    <Page
      className={classes.root}
      title="Detalle anuncio"
    >
      <Container maxWidth="lg">
        <Grid
          item
          xs={12}
        >
          <Card style={{
            position: 'relative'
          }}>
            <CardHeader
              title="Detalle de anuncio"
            />
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={4}
                md={5}
                sm={6}
                xs={12}
                style={{ paddingTop: 30 }}
              >
                <div className="navTabs">
                    <ul>
                      <li>
                        <Button href="#" onClick={(e) => {e.preventDefault();setActiveTab(1);}} className={activeTab===1?'active':''}>Información basica</Button>
                      </li>
                      <li>
                        <Button href="#" onClick={(e) => {e.preventDefault();setActiveTab(2);}} className={activeTab===2?'active':''}>Ubicación</Button>
                      </li>
                      {<li>
                        <Button href="#" onClick={(e) => {e.preventDefault();setActiveTab(3);}} className={activeTab===3?'active':''}>Características</Button>
                      </li>}
                      <li>
                        <Button href="#" onClick={(e) => {e.preventDefault();setActiveTab(4);}} className={activeTab===4?'active':''}>Imagenes</Button>
                      </li>
                      <li>
                        <Button href="#" onClick={(e) => {e.preventDefault();setActiveTab(5);}} className={activeTab===5?'active':''}>Disponibilidad</Button>
                      </li>
                      <li>
                        <Button href="#" onClick={(e) => {e.preventDefault();setActiveTab(6);}} className={activeTab===6?'active':''}>Acciones</Button>
                      </li>
                    </ul>
                </div>
              </Grid>
              <Grid
                item
                lg={8}
                md={7}
                sm={6}
                xs={12}
                style={{padding: 30}}
              >
                {activeTab===1 ?
                  <InformacionBasica propAdd={add}/>
                :''}
                {activeTab===2 ?
                  <Ubicacion propAdd={add} propSetAdd={setAdd} propSetActiveTab={setActiveTab}/>
                :''}
                {activeTab===3 ?
                  <Caracteristicas propAdd={add} propSetAdd={setAdd} propSetActiveTab={setActiveTab}/>
                :''}
                {activeTab===4 ?
                  <ImageManagerTwo propAdd={add} propSetAdd={setAdd} propSetActiveTab={setActiveTab}/>
                :''}
                {activeTab===5 ?
                  <Disponibilidad propAdd={add} propSetAdd={setAdd}/>
                :''}
                {activeTab===6 ?
                  <Acciones propAdd={add} propSetAdd={setAdd}/>
                :''}
                {activeTab===40 ?
                  <Comodin propReturn={4} propSetActiveTab={setActiveTab}/>
                :''}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
};

export default Anuncio;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { publicServices } from '../../services/publicServices';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const OlvideContrasena = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  return (
    <Page
      className={classes.root}
      title="Recupera tu contraseña"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Ingresa un email valido').max(255).required('El email es requerido')
            })}
            onSubmit={(values) => {
              setDisable(true);
              publicServices.generarRecoverLink(values)
                .then(response => {
                  if (response.status === 201) {
                    navigate('/login', { replace: true });
                  }
                })
                .catch(function (error) {
                }).then(function () {
                  setDisable(false);
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Recupera tu contraseña
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Ingresa tu email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={disable}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    RECUPERAR
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default OlvideContrasena;

import React from 'react';
import { Outlet } from 'react-router-dom';
import NavbarPrincipalCliente from './NavbarPrincipalCliente';
import NavbarPrincipalPropietario from './NavbarPrincipalPropietario';
import Footer from './Footer';
import "../../styles/argon_theme/vendor/nucleo/css/nucleo.css";
import "../../styles/argon_theme/vendor/font-awesome/css/font-awesome.min.css";
import "../../styles/argon_theme/scss/argon-design-system-react.scss?v1.1.0";

class PublicLayout extends React.Component {
  componentDidMount() {
    //localStorage.clear();
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <React.Fragment>
        {this.props.propCliente===true ?
          <NavbarPrincipalCliente propSolid={this.props.propSolid}/>
          :
          <NavbarPrincipalPropietario propSolid={this.props.propSolid}/>
        }
        <div ref="main">
          <Outlet />
        </div>
        <Footer/>
      </React.Fragment>
    );
  }
}

export default PublicLayout;
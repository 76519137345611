import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Link,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Toolbar from './Toolbar';
import { sellerServices } from '../../services/sellerServices';
import image_default from '../../icons/image_default.svg';
const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, ...rest }) => {
  const classes = useStyles();
  const [selectedAddsIds, setSelectedAddsIds] = useState([]);
  const [page, setPage] = useState(0);
  const [adds, setAdds] = useState(null);

  useEffect(() => {
      getAdds(1);
  }, []);

  function getAdds(page){
      sellerServices.getAdds(page, 10)
      .then(response => {
        if (response.status===200) {
          setAdds(response.data);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  const handleSelectAll = (event) => {
    let newSelectedAddsIds;

    if (event.target.checked) {
      newSelectedAddsIds = adds.data.map((add) => add.id);
    } else {
      newSelectedAddsIds = [];
    }

    setSelectedAddsIds(newSelectedAddsIds);
    console.log('newSelectedAddsIds==>')
    console.log(newSelectedAddsIds)
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedAddsIds.indexOf(id);
    let newSelectedAddsIds = [];

    if (selectedIndex === -1) {
      newSelectedAddsIds = newSelectedAddsIds.concat(selectedAddsIds, id);
    } else if (selectedIndex === 0) {
      newSelectedAddsIds = newSelectedAddsIds.concat(selectedAddsIds.slice(1));
    } else if (selectedIndex === selectedAddsIds.length - 1) {
      newSelectedAddsIds = newSelectedAddsIds.concat(selectedAddsIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedAddsIds = newSelectedAddsIds.concat(
        selectedAddsIds.slice(0, selectedIndex),
        selectedAddsIds.slice(selectedIndex + 1)
      );
    }

    setSelectedAddsIds(newSelectedAddsIds);
  };

  const handlePageChange = (event, newPage) => {
    getAdds(newPage);
  };

  return (
    <React.Fragment>
      <React.Fragment>
      <Toolbar />
      {adds!==null && adds.data.length>0 ? 
      <Box mt={3}>
        <Card
          className={clsx(classes.root, className)}
          {...rest}
        >
          <React.Fragment>
            <PerfectScrollbar>
              <Box minWidth={1050}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedAddsIds.length === adds.data.length}
                          color="primary"
                          indeterminate={
                            selectedAddsIds.length > 0
                            && selectedAddsIds.length < adds.data.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell>
                        Fotografía
                      </TableCell>
                      <TableCell>
                        Tipo
                      </TableCell>
                      <TableCell>
                        Titulo
                      </TableCell>
                      <TableCell>
                        Dirección
                      </TableCell>
                      <TableCell>
                        Descripción
                      </TableCell>
                      <TableCell>
                        Fecha de registro
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adds.data.map((add) => (
                      <TableRow
                        hover
                        key={add.id}
                        selected={selectedAddsIds.indexOf(add.id) !== -1}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedAddsIds.indexOf(add.id) !== -1}
                            onChange={(event) => handleSelectOne(event, add.id)}
                            value="true"
                          />
                        </TableCell>
                        <TableCell>
                          <Link
                            component={RouterLink}
                            to={"/vendor/anuncio/"+add.id+"/"}
                            variant="span"
                          >
                            <Avatar
                              style={{'width': '100px','height': '100px'}}
                              variant="square"
                              src={add.imagenes.length>0?add.imagenes[0].url_thumb:image_default}  
                            />
                          </Link>
                        </TableCell>
                        <TableCell>
                          <span style={{'display':'block'}} >{add.tipo_anuncio.nombre}</span>
                          <span><small>{add.subtipo_anuncio.nombre}</small></span>
                        </TableCell>
                        <TableCell>
                          {add.titulo}
                        </TableCell>
                        <TableCell>
                          {add.calle!==null ?
                            add.calle+', '+add.colonia+', '+add.numero
                          :''}
                          {add.carretera!==null ?
                            'Carretera '+add.carretera
                          :''}
                          {add.kilometro!==null?
                            '. Km '+add.kilometro
                          :''}
                        </TableCell>
                        <TableCell>
                          {add.descripcion_corta}
                        </TableCell>
                        <TableCell>
                          {moment(add.created_at).format('DD/MM/YYYY')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
            <div className="container_pagination">
              <Pagination count={adds.meta.last_page} page={adds.meta.current_page} onChange={handlePageChange} />
            </div>
          </React.Fragment>
        </Card>
      </Box>
      : ''}
      </React.Fragment>
    
    </React.Fragment>
  );

};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;

import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import RegistroPropietario from './RegistroPropietario';
import publicar_img from '../../icons/publicar_img.svg';
import rentar_img from '../../icons/rentar_img.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const RegisterView = () => {

  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Registro"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
            <Box mb={3}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Cual es tu interes?
              </Typography>
            </Box>
            <Box>
              <div className="selectUserType">
                <div>
                  <RouterLink to="/registro-propietario">
                    <img src={publicar_img} alt="Anunciotes.com" />
                    <span>
                      <strong>Publicar</strong> espacios publicitarios
                    </span>
                  </RouterLink>
                </div>
                <div>
                  <RouterLink to="/registro-cliente">
                    <img src={rentar_img} alt="Anunciotes.com" />
                      <span>
                        <strong>Rentar</strong> espacios publicitarios
                      </span>
                  </RouterLink>
                </div>
              </div>
            </Box>

            {/*<RegistroPropietario/>*/}
        </Container>
      </Box>
    </Page>
  );
};

export default RegisterView;
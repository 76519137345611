import { axiosApiInstance, axiosApiInstanceFormData, axiosApiInstanceHeaders } from './ManageToken'
import { showToastr, toastrError } from '../utils/toast';

function getAdds(page, limit){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/anuncios`,
      { params: {
          page: page,
          limit: limit
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getAdd(id){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/anuncios/`+id,
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getTiposAnuncio(){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/tipos`
    )
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getSubtiposAnuncio(id){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/anuncios/tipos/`+id+`/subtipos`
    )
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      toastrError(error);
    });
}


function getEstados(){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/estados`
    )
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getMunicipiosEstado(id){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/estado/`+id+`/municipios`
    )
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function newAdd(object_data){
  return axiosApiInstanceFormData.post(
      `${process.env.REACT_APP_API_URL}v2/anuncios`,
      object_data
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'Has publicado un nuevo anuncio.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateAdd(object_data, id){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_URL}v2/anuncios/`+id,
      object_data
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'Se ha actualizado el anuncio.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}


function deleteAdd(id){
  return axiosApiInstance.delete(
      `${process.env.REACT_APP_API_URL}v2/anuncios/`+id
    )
    .then(response => {
      if (response.status===204) {
          showToastr('success', 'Correcto.', 'El anuncio ha sido eliminado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function lastAdds(){
  return axiosApiInstanceHeaders.post(
      `${process.env.REACT_APP_API_URL}public/anuncios/ultimos`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function completarVendedor(object_data){
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_URL}v2/vendedor`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          localStorage.setItem('user_data', JSON.stringify(response.data.user));
          showToastr('success', 'Correcto.', 'Has completado tu perfil.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getProfile(){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/user/profile`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updatePerfil(object_data){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_URL}v2/vendedor`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has actualizado tu perfil.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updatePerfilCliente(object_data){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_URL}v2/updateCliente`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has actualizado tu perfil.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function syncGoogle(googleId){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_URL}v2/syncSocialNetwork`,
        {
            google_id: googleId
        }
    )
    .then(response => {
      if (response.status===201) {
          localStorage.setItem('user_data', JSON.stringify(response.data.user));
          if (googleId==='') {
            showToastr('success', 'Correcto!', 'Cuenta desvinculada.', 2500, true, true, 'top-right');
          }else{
            showToastr('success', 'Muy bien!', 'Tu cuenta de google se ha sincronizado.', 2500, true, true, 'top-right');
          }
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function syncFacebook(facebookId){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_URL}v2/syncSocialNetwork`,
        {
            facebook_id: facebookId
        }
    )
    .then(response => {
      if (response.status===201) {
          localStorage.setItem('user_data', JSON.stringify(response.data.user));
          if (facebookId==='') {
            showToastr('success', 'Correcto!', 'Cuenta desvinculada.', 2500, true, true, 'top-right');
          }else{
            showToastr('success', 'Muy bien!', 'Tu cuenta de facebook se ha sincronizado.', 2500, true, true, 'top-right');
          }
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function changePassword(object_data){
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_URL}v2/changePassword`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has actualizado tu contaseña.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getSpecs(page, limit){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/specs`,
      { params: {
          page: page,
          limit: limit
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateSpec(object_data){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_URL}v2/specs/`+object_data.id,
      object_data
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'Característica actualizada.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function deleteSpecs(ids){
  let obj_ids = Object.keys(ids).map(function(k){return ids[k]}).join(",");
  console.log('obj_ids==>');
  console.log(obj_ids);
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_URL}v2/specs/deleteMultiple`,
      { obj_ids: obj_ids }
    )
    .then(response => {
      if (response.status===204) {
          showToastr('success', 'Correcto.', 'El característica ha sido eliminada.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function addSpec(object_data){
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_URL}v2/specs`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has agregado una nueva característica.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getFreeSpecs(id_tipo, id_add){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/getSpecs/`+id_tipo+`/`+id_add
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}


function getSpecsAssigned(id_add){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/getSpecsAssigned/`+id_add
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function addSpecAssigned(object_data, id_add){
  let obj_ids = Object.keys(object_data).map(function(k){
    return object_data[k].id;
  }).join(",");
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_URL}v2/anuncioSpec/add/`+id_add,
      { obj_ids: obj_ids }
    )
    .then(response => {
      if (response.status===201 || response.status===200) {
          showToastr('success', 'Correcto.', 'Has modificado las características de tu anuncio.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function addFecha(object_data, anuncio_id){
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_URL}v2/fecha`,
        {
            anuncio_id: anuncio_id,
            ...object_data
        }
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has agregado una nuevo rango de fechas de reservación.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function removeFecha(object_data, anuncio_id){
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_URL}v2/deleteFecha`,
        {
            anuncio_id: anuncio_id,
            ...object_data
        }
    )
    .then(response => {
      if (response.status===204) {
          showToastr('success', 'Correcto.', 'Has eliminado el rango de fechas seleccionado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getFechasByAdd(id_add){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/fechas/`+id_add
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function deleteImage(id_img) {
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/anuncios/imagenes/`+id_img
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'Has eliminado una imagen.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function saveImagenPrincipal(id_add,id_img){
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_URL}v2/anuncios/imagenPrincipal`,
        {
            anuncio_id: id_add,
            imagen_id: id_img
        }
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'Has modificado la imagen principal.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

export const sellerServices = {
  getAdds,
  getTiposAnuncio,
  getSubtiposAnuncio,
  getEstados,
  getMunicipiosEstado,
  newAdd,
  updateAdd,
  getAdd,
  lastAdds,
  completarVendedor,
  getProfile,
  updatePerfil,
  updatePerfilCliente,
  deleteAdd,
  changePassword,
  getSpecs,
  updateSpec,
  deleteSpecs,
  addSpec,
  getFreeSpecs,
  getSpecsAssigned,
  addSpecAssigned,
  addFecha,
  getFechasByAdd,
  removeFecha,
  syncGoogle,
  syncFacebook,
  deleteImage,
  saveImagenPrincipal
};

import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import homeImage from '../../icons/imgs/1022523431.jpg';
import arrowdown from '../../icons/arrow_double_down.svg';
import logo_white from '../../icons/logo_white.svg';
import TitleOne from '../ui/micro';
import LastAdds from './LastAdds';
import Tipos from './Tipos';
import MapWithCluster from '../ui/MapWithCluster';

import { Link, animateScroll as scroll } from "react-scroll";

const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY_MAPS}&v=3.exp&libraries=geometry,drawing,places`;

const Home = () => {

  useEffect(() => {
     document.title = "Anunciotes - Inicio"
  }, []);

  return (
      <React.Fragment>

        <Container className="topmain" fluid={true} style={{ backgroundImage: `url(${homeImage})` }}>
          <div className="section-angled-layer-bottom bg-light"></div>
          <Row className="h-100 align-items-center">
            <Container>
              <Row>
                <Col xs="12" md="8" lg="6" xl="5">
                  <Row>
                    <Col xs="12" className="text-center text-md-left">
                      <h1 className="light fw_900 extra-size">PUBLICIDAD EXTERIOR EN QUERETARO</h1>
                      <h4 className="light fw_500 text-uppercase">Encuentra lugares estratégicos para llegar a tu público objetivo</h4>
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-items-center">
                    <Col sm={{ size: 'auto' }}>
                      <Link
                        className="ease_vertical"
                        activeClass="active"
                        to="nuestra-oferta"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                      >
                        <img src={arrowdown} alt=""/>
                      </Link>
                      {/*<a className="ease_vertical"  href="#nuestra-oferta">
                      </a>*/}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>

        <Container className="pt-5 pb-7" id="tipos">
          <Row>
            <Tipos/>
          </Row>
        </Container>

        <Container fluid={true} className="py-7" id="nuestra-oferta">
          <Row>
            <Col xs="12" className="text-center">
              <TitleOne part_one="CONOCE NUESTRA " part_two="OFERTA" line={true}/>
            </Col>
          </Row>
          <Row className="pt-5">
            <MapWithCluster
              googleMapURL={mapUrl}
              loadingElement={<span>Cargando</span>}
              containerElement={<div style={{ height: '75vh',minHeight: '500px',width: '100%' }} />}
              mapElement={<div style={{ height: '100%' }} />}
              zoom={5}
              public={true}
            />
          </Row>
        </Container>

        <Container className="pb-7">
          <Row>
            <Col xs="12" className="text-center">
              <TitleOne part_one="ULTIMOS " part_two="ESPACIOS PUBLICITARIOS" line={true}/>
            </Col>
          </Row>
          <Row className="pt-5">
            <LastAdds/>
          </Row>
        </Container>

      </React.Fragment>
  );
};

export default Home;

import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import homeImage from '../../../icons/imgs/1022523431.jpg';
import arrowdown from '../../../icons/arrow_double_down.svg';
import logo_white from '../../../icons/logo_white.svg';
import TitleOne from '../../ui/micro';
import MapWithCluster from '../../ui/MapWithCluster';

const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY_MAPS}&v=3.exp&libraries=geometry,drawing,places`;

const Propietario = () => {

  useEffect(() => {
     document.title = "Anunciotes - Inicio"
  }, []);

  return (
      <React.Fragment>

        <Container className="topmain" fluid={true} style={{ backgroundImage: `url(${homeImage})` }}>
          <div className="section-angled-layer-bottom bg-light"></div>
          <Row className="h-100 align-items-center">
            <Container>
              <Row>
                <Col xs="12" md="8" lg="6" xl="5">
                  <Row>
                    <Col xs="12" className="text-left">
                      <h2 className="light fw_600">FORMA PARTE DE NUESTRO CATALOGO DE ESPACIOS PUBLICITARIOS</h2>
                    </Col>
                    <Col xs="12">
                      <Button color="primary" to="/registro" component={RouterLink} tag={RouterLink}>REGISTRATE GRATIS</Button>
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-items-center">
                    <Col sm={{ size: 'auto' }}>
                      <a className="ease_vertical" href="#nuestra-oferta">
                        <img src={arrowdown} alt=""/>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>

        <Container fluid={true} className="py-7" id="nuestra-oferta">
          <Row>
            <Col xs="12" className="text-center">
              <TitleOne part_one="CONOCE NUESTRA " part_two="OFERTA" line={true}/>
            </Col>
          </Row>
          <Row className="pt-5">
            <MapWithCluster
              googleMapURL={mapUrl}
              loadingElement={<span>Cargando</span>}
              containerElement={<div style={{ height: '75vh',minHeight: '500px',width: '100%' }} />}
              mapElement={<div style={{ height: '100%' }} />}
              zoom={5}
              public={true}
            />
          </Row>
        </Container>

      </React.Fragment>
  );
};

export default Propietario;

import React from "react";
// JavaScript plugin that hides or shows a component based on your scroll
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";


class Footer extends React.Component {
  render() {
    return (
        <Container fluid={true} className="bg-secondary py-2">
          <Row className="justify-content-center align-items-center">
            <Col xs="12" className="text-center light">
              Copyright Anunciotes.com 2021. Todos los Derechos Reservados
            </Col>
            <Col xs="12" className="text-center light">
              Aviso de Privacidad
            </Col>
          </Row>
        </Container>
    );
  }
}

export default Footer;

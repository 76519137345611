import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import validator from 'validator';
import image_default from '../../../icons/image_default.svg';
import { sellerServices } from '../../../services/sellerServices';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

Yup.setLocale({
  mixed: {
    required: 'Esta campo es requerido',
  },
  string: {
    required: 'Esta campo es requerido',
    max: 'El texto es demasiado largo',
    min: 'El texto es demasiado corto',
  },
  number: {
    integer: 'Solo se aceptan datos numericos'
  }
})

const Profile = (props) => {

  const [profile_, setProfile_] = useState(props.propProfile);
  const setProfile = props.propSetProfile;
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setProfile_(props.propProfile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.propProfile]);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const responseGoogle = (response) => {
    console.log('response==>');
    console.log(response);
    var res = response.profileObj;
    updateGoogleId(res.googleId);
  }

  const responseFacebook = (response) => {
    updateFacebookId(response.userID);
  }

  function updateGoogleId(id){
    setDisabled(true);
    sellerServices.syncGoogle(id)
      .then(response => {
        if (response.status === 201) {
          setProfile_(response.data.user);
        }
      })
      .catch(function (error) {
      }).then(function () {
        setDisabled(false);
      });
  }

  function updateFacebookId(id){
    setDisabled(true);
    sellerServices.syncFacebook(id)
      .then(response => {
        if (response.status === 201) {
          setProfile_(response.data.user);
        }
      })
      .catch(function (error) {
      }).then(function () {
        setDisabled(false);
      });
  }



  return (
    <React.Fragment>
    { profile_ !==null ?
      <Card>
        <CardContent>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h3"
            >
              {profile_.name}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              {profile_.email}
            </Typography>
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            onClick={handleClickOpen}
          >
            {(profile_!==null && profile_.pass !== null)?'Cambiar contraseña':'Crear contraseña'}
          </Button>
        </CardActions>
        <CardActions>
          
          {profile_.google_id===null || profile_.google_id==='' ?
            <GoogleLogin
              clientId="458448170505-5nbcnkiebdvnmk1kf01m0ubt1r8vfkaf.apps.googleusercontent.com"
              render={renderProps => (
                <Button
                  className="btn_custom btn_google light"
                  fullWidth
                  variant="text"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                   Vincula con <i className="fa fa-google ml-1" aria-hidden="true"></i>
                </Button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              isSignedIn={false}
              cookiePolicy={'single_host_origin'}
            />
          :
            <Button
              className="btn_custom btn_google light desync"
              fullWidth
              variant="text"
              onClick={e=>{updateGoogleId('');}}
              disabled={disabled}
            >
               Desvincula <i className="fa fa-google ml-1" aria-hidden="true"></i>
            </Button>
          }

          {profile_.facebook_id===null || profile_.facebook_id==='' ?
            <FacebookLogin
              appId="2002289936584627"
              autoLoad={false}
              callback={responseFacebook}
              render={renderProps => (
                <Button
                  className="btn_custom btn_facebook light"
                  fullWidth
                  variant="text"
                  onClick={renderProps.onClick}
                >
                  Vincula con <i className="fa fa-facebook ml-1" aria-hidden="true"></i>
                </Button>
              )}
            />
          :
            <Button
              className="btn_custom btn_facebook light desync"
              fullWidth
              variant="text"
              onClick={e=>{updateFacebookId('');}}
              disabled={disabled}
            >
               Desvincula <i className="fa fa-facebook ml-1" aria-hidden="true"></i>
            </Button>
          }
        </CardActions>


      <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true} aria-labelledby="form-dialog-title">
        <Formik
          initialValues={{
            password: '',
            new_password: '',
            confirm: '',
          }}
          validationSchema={Yup.object().shape({
            password: (profile_!==null && profile_.pass !== null) ? Yup.string().required() : null,
            new_password: Yup.string().max(20).required('La contraseña es requerida').test('test-name', 'La contraseña no cumple las condiciones seguras', 
              function(value) {
                if (value!==undefined) {
                  if (validator.isStrongPassword(value, {
                    minLength: 8, minLowercase: 1,
                    minUppercase: 1, minNumbers: 1, minSymbols: 1
                  })) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }),
            confirm: Yup.string().required('La contraseña es requerida').oneOf([Yup.ref('new_password'), null], 'La contraseña no coincide')
          })}
          onSubmit={(values) => {
            sellerServices.changePassword(values)
            .then(response => {
              if (response.status===201) {
                setProfile(response.data.profile);
                console.log('response.data.profile');
                console.log(response.data.profile);
                setOpen(false);
              }
            })
            .catch(function (error) {
                return error;
            })
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              autoComplete="off"
              onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}
              style={{
                display: 'inline-block',
                width: '100%',
              }}
            >
              <DialogTitle id="form-dialog-title">{(profile_!==null && profile_.pass !== null) ? 'Cambiar contraseña' : 'Crear contraseña' }</DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={3}
                >
                  {(profile_!==null && profile_.pass !== null) ?
                    <Grid
                      item
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        name="password"
                        onChange={(e) => {handleChange(e); }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        value={values.password}
                        variant="outlined"
                        label="Contraseña actual"
                        type="password"
                      />
                    </Grid>
                  :''}
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      name="new_password"
                      onChange={(e) => {handleChange(e); }}
                      error={Boolean(touched.new_password && errors.new_password)}
                      helperText={touched.new_password && errors.new_password}
                      onBlur={handleBlur}
                      value={values.new_password}
                      variant="outlined"
                      label="Nueva contraseña"
                      type="password"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      name="confirm"
                      onChange={(e) => {handleChange(e); }}
                      error={Boolean(touched.confirm && errors.confirm)}
                      helperText={touched.confirm && errors.confirm}
                      onBlur={handleBlur}
                      value={values.confirm}
                      variant="outlined"
                      label="Repite la nueva contraseña"
                      type="password"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="default">
                  Cancelar
                </Button>
                <Button type="submit" color="primary">
                  Cambiar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>

      </Dialog>


      </Card>
    :''}
    </React.Fragment>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;

import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { 
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Label,
  Input,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  Badge,
  Card,
  CardBody,
  CardImg,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from 'reactstrap';
import topHeader from '../../icons/imgs/topHeader.jpg';
import { publicServices } from '../../services/publicServices';
import plus from '../../icons/plus.svg';
import image_default from '../../icons/image_default.svg';
import * as Yup from 'yup';
import { Formik } from 'formik';

const Contacto = () => {

  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);

  useEffect(() => {
     document.title = "Anunciotes - Contacto"
  }, []);

  return (
      <React.Fragment>

        <Container fluid={true} className="topM" style={{backgroundImage: `url(${topHeader})`}}>
          <div className="overlay"></div>
          <Container className="pt-5 pb-3">
            <Row>
              <Col xs="12" className="text-left">
                <h2 className="fw_600 light">Contactanos</h2>
              </Col>
              <Col xs="12" className="text-left">
                <Breadcrumb tag="nav" listTag="div" className="cs_01">
                  <BreadcrumbItem to="/" component={RouterLink} tag={RouterLink}>
                    <i className="fa fa-home" aria-hidden="true"></i>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Contacto</BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container className="container-lg py-7">
          <Row className="align-items-center">
            <Col xs="12" md="6">
              <Card className="bg-gradient-secondary shadow">
                <CardBody className="p-lg-5">
                  <h4 className="mb-1 light">¿Tienes alguna duda?</h4>
                  <p className="mt-0 light">
                    Escribenos y nos comunicaremos contigo cuanto antes.
                  </p>
                  <Formik
                    initialValues={{
                      email: '',
                      nombre: '',
                      mensaje: ''
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string().email('Ingresa un email valido').max(100).required('El email es requerido'),
                      nombre: Yup.string().max(255).required('El nombre es requerido'),
                      mensaje: Yup.string().max(500).required('El mensaje es requerido'),
                    })}
                    onSubmit={(values, {resetForm}) => {
                      setDisable(true);
                      publicServices.sendContact(values)
                        .then(response => {
                          if (response.status === 201) {
                            resetForm({});
                          }
                        })
                        .catch(function (error) {
                        }).then(function () {
                          setDisable(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values
                    }) => (
                      <form onSubmit={handleSubmit}>
                          <FormGroup>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-user-run" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Nombre"
                                type="text"
                                name="nombre"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.nombre}
                                invalid={Boolean(touched.nombre && errors.nombre)}
                              />
                              {errors.nombre && touched.nombre ? (
                                <div className="invalid-feedback d-block">{errors.nombre}</div>
                              ) : null}
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-83" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Email"
                                type="email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                invalid={Boolean(touched.email && errors.email)}
                              />
                              {errors.email && touched.email ? (
                                <div className="invalid-feedback d-block">{errors.email}</div>
                              ) : null}
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className="mb-4">
                            <Input
                              placeholder="Mensaje"
                              cols="80"
                              rows="4"
                              type="textarea"
                              name="mensaje"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.mensaje}
                              invalid={Boolean(touched.mensaje && errors.mensaje)}
                            />
                            {errors.mensaje && touched.mensaje ? (
                              <div className="invalid-feedback d-block">{errors.mensaje}</div>
                            ) : null}
                          </FormGroup>
                          <div>
                            <Button
                              block
                              className="btn-round"
                              color="primary"
                              size="lg"
                              type="submit"
                              disabled={disable}
                            >
                              Enviar
                            </Button>
                          </div>
                      </form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="6">
              <Row>
                <Col xs="12" className="mb-3">
                  <ul className="list-unstyled">
                    <li className="py-1">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="primary"
                          >
                            <i class="fa fa-phone" aria-hidden="true"></i>
                          </Badge>
                        </div>
                        <div>
                          <a href="tel:4427139765" className="mb-0">+52 (442) 713 97 65</a>
                        </div>
                      </div>
                    </li>
                    <li className="py-1">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="primary"
                          >
                            <i class="fa fa-whatsapp" aria-hidden="true"></i>
                          </Badge>
                        </div>
                        <div>
                          <a href="tel:4427139765" className="mb-0">+52 (442) 713 97 65</a>
                        </div>
                      </div>
                    </li>
                    <li className="py-1">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="primary"
                          >
                            <i className="ni ni-square-pin" />
                          </Badge>
                        </div>
                        <div>
                          <a href="https://goo.gl/maps/QRCceKGQPtHFQ6DGA" target="_blank" className="mb-0">
                            Av. Pie de la Cuesta 1709, Los Robles, 76148 Santiago de Querétaro, Qro.
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col xs="12">
                  <Button
                    className="btn-icon-only rounded-circle"
                    color="secondary"
                    href="#"
                    onClick={e => e.preventDefault()}
                  >
                    <i className="fa fa-twitter" />
                  </Button>
                  <Button
                    className="btn-icon-only rounded-circle ml-1"
                    color="secondary"
                    href="#"
                    onClick={e => e.preventDefault()}
                  >
                    <i className="fa fa-facebook" />
                  </Button>
                  <Button
                    className="btn-icon-only rounded-circle ml-1"
                    color="secondary"
                    href="#"
                    onClick={e => e.preventDefault()}
                  >
                    <i className="fa fa-dribbble" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

      </React.Fragment>
  );
};

export default Contacto;

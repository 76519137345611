import axios from 'axios';
import { showToastr, toastrError } from '../utils/toast';
import { axiosApiInstanceHeaders } from './ManageToken'

async function login(values) {
  return axiosApiInstanceHeaders.post(
    `${process.env.REACT_APP_API_URL}login`,
    values
  ).then(response => {
    if (response.status === 201) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user_data', JSON.stringify(response.data.user));
      showToastr('success', 'Correcto.', 'Sesión iniciada.', 2500, true, true, 'top-right');
    }else{
      showToastr('error', 'Email/contraseña incorrectos.', 'Vuelve a intentarlo.', 2500, true, true, 'top-right');
    }
    return response;
  }).catch(function (error) {
    toastrError(error);
    return Promise.reject(error);
  });
}

function googleLogin(googleId){
  return axiosApiInstanceHeaders.post(
    `${process.env.REACT_APP_API_URL}login`,
    {
        google_id: googleId
    }
  ).then(response => {
    if (response.status === 201) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user_data', JSON.stringify(response.data.user));
      showToastr('success', 'Correcto.', 'Sesión iniciada con google.', 2500, true, true, 'top-right');
    }
    return response;
  }).catch(function (error) {
    showToastr('error', 'Error.', 'La cuenta no esta vinculada.', 2500, true, true, 'top-right');
    return Promise.reject(error);
  });
}

function facebookLogin(userID){
  return axiosApiInstanceHeaders.post(
    `${process.env.REACT_APP_API_URL}login`,
    {
        facebook_id: userID
    }
  ).then(response => {
    if (response.status === 201) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user_data', JSON.stringify(response.data.user));
      showToastr('success', 'Correcto.', 'Sesión iniciada con facebook.', 2500, true, true, 'top-right');
    }
    return response;
  }).catch(function (error) {
    showToastr('error', 'Error.', 'La cuenta no esta vinculada.', 2500, true, true, 'top-right');
    return Promise.reject(error);
  });
}



function logout(){
    localStorage.clear();
    showToastr('info', 'Sesión cerrada','Has salido de tu cuenta.', 2500, true, true, 'top-right');
    return true;
}

export const authServices = {
  login,
  googleLogin,
  facebookLogin,
  logout
};

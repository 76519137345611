import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { sellerServices } from '../../../services/sellerServices';


const ProfileDetails = (props) => {

  const profile_ = props.propProfile;
  const setProfile = props.propSetProfile;

  const [disabled, setDisabled] = useState(false);

  return (
    <React.Fragment>
    {profile_!==null ?
      <Formik
        initialValues={{
          empresa: profile_.vendedor!==null?profile_.vendedor.empresa:'',
          telefono: profile_.vendedor!==null?profile_.vendedor.telefono:'',
          whatsapp: profile_.vendedor!==null?profile_.vendedor.whatsapp:'',
          web: profile_.vendedor!==null?profile_.vendedor.web:'',
          name: profile_.name,
          email: profile_.email
        }}
        validationSchema={Yup.object().shape({
          empresa: Yup.string().max(100),
          telefono: Yup.string().max(15),
          whatsapp: Yup.string().max(100).required('El whatsapp es requerido'),
          web: Yup.string().max(150),
          name: Yup.string().max(150),
          email: Yup.string().max(150),
        })}
        onSubmit={(values) => {
          setDisabled(true);
          sellerServices.updatePerfil(values)
            .then(response => {
              if (response.status === 201) {
                setProfile(response.data.data);
              }
            })
            .catch(function (error) {
            }).then(function () {
              setDisabled(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
          >
            <Card>
              <CardHeader
                subheader="Puedes editar la información"
                title="Mi perfil"
              />
              <Divider />
              <CardContent>
                <Grid
                  item
                  xs={12}
                >
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        label="Nombre"
                        margin="normal"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <CardHeader
                title="Empresa"
              />
              <Divider />

              <CardContent>
                <Grid
                  item
                  xs={12}
                >
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.empresa && errors.empresa)}
                        fullWidth
                        helperText={touched.empresa && errors.empresa}
                        label="Empresa"
                        margin="normal"
                        name="empresa"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.empresa}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.telefono && errors.telefono)}
                        fullWidth
                        helperText={touched.telefono && errors.telefono}
                        label="Telefono"
                        margin="normal"
                        name="telefono"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.telefono}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.whatsapp && errors.whatsapp)}
                        fullWidth
                        helperText={touched.whatsapp && errors.whatsapp}
                        label="Whatsapp"
                        margin="normal"
                        name="whatsapp"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.whatsapp}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.web && errors.web)}
                        fullWidth
                        helperText={touched.web && errors.web}
                        label="Pagina web"
                        margin="normal"
                        name="web"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.web}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>


              <Divider />
              <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={disabled}
                >
                  Guardar
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
    : ''}
    </React.Fragment>
  );
};

export default ProfileDetails;

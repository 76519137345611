import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import marker_1 from '../../icons/marker_1.svg';
import marker_2 from '../../icons/marker_2.svg';
import marker_3 from '../../icons/marker_3.svg';
import marker_4 from '../../icons/marker_4.svg';
import marker_5 from '../../icons/marker_5.svg';
import marker_6 from '../../icons/marker_6.svg';
import marker_7 from '../../icons/marker_7.svg';

const MapWithAMarker = withScriptjs(withGoogleMap(props => {
    var icon='';
    console.log('props.iconType==>');
    console.log(props.iconType);
    switch (parseInt(props.iconType)) {
      case 1:
        icon=marker_1;
        break;
      case 2:
        icon=marker_2;
        break;
      case 3:
        icon=marker_3;
        break;
      case 4:
        icon=marker_4;
        break;
      case 5:
        icon=marker_5;
        break;
      case 6:
        icon=marker_6;
        break;
      case 7:
        icon=marker_7;
        break;
      default:
        icon=marker_1;
        break;
    }
    return(
      <GoogleMap
        defaultZoom={props.zoom}
        defaultCenter={{ lat: props.latitud, lng: props.longitud }}
      >
        <Marker
          position={{ lat: props.latitud, lng: props.longitud }}
          icon={
            {
              url: icon,
              scaledSize: new window.google.maps.Size(50, 65)
            }
          }
        />
      </GoogleMap>
    )
  }
));

export default MapWithAMarker;
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { 
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner
} from 'reactstrap';
import topHeader from '../../icons/imgs/topHeader.jpg';
import { publicServices } from '../../services/publicServices';
import plus from '../../icons/plus.svg';
import image_default from '../../icons/image_default.svg';
import * as Yup from 'yup';
import { Formik } from 'formik';

const EspaciosPublicitarios = () => {

  useEffect(() => {
     document.title = "Anunciotes - Espacios Publicitarios"
  }, []);

  const { tipo_p, subtipo_p } = useParams();

  const [adds, setAdds] = useState(null);
  const [meta, setMeta] = useState(null);
  const [tipos, setTipos] = useState(null);
  const [tipoSelected, setTipoSelected] = useState([]);
  const [subtipos, setSubtipos] = useState(null);
  const [subtipoSelected, setSubtipoSelected] = useState([]);
  const [estados, setEstados] = useState(null);
  const [estadoSelected, setEstadoSelected] = useState([]);
  const [municipios, setMunicipios] = useState(null);
  const [municipioSelected, setMunicipioSelected] = useState([]);
  const [order, setOrder] = useState(null);
  const [valueSearch, setValueSearch] = useState(null);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
      getTipos();
      if (tipo_p!==undefined) {
        tipoSelected.push(parseInt(tipo_p));
        getMultiSubtipos_(tipoSelected);
        setTipoSelected([...tipoSelected]);
      }else{
        setTipoSelected([]);
      }
      getEstadosEnUso_();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
      getAdds(null);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueSearch]);

  function getEstadosEnUso_(){
      publicServices.getEstadosEnUso()
      .then(response => {
        if (response.status===200) {
          setEstados(response.data.data);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  function getMunicipiosEnUso_(obj_estados){
    if (obj_estados.length>0) {
      publicServices.getMunicipiosEnUsoPorEstado(obj_estados)
      .then(response => {
        if (response.status===200) {
          setMunicipios(response.data.data);
        }
      })
      .catch(function (error) {
          return error;
      })
    }else{
      setMunicipios([]);
    }
  }

  function getTipos(){
      publicServices.getTiposAnuncio()
      .then(response => {
        if (response.status===200) {
          setTipos(response.data.data);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  function getMultiSubtipos_(tipoSelected){
    if (tipoSelected.length>0) {
      publicServices.getMultiSubtipos(tipoSelected)
      .then(response => {
        if (response.status===200) {
          setSubtipos(response.data.data);
        }
      })
      .catch(function (error) {
        return error;
      })
    }else{
      setSubtipos([]);
    }
  }

  function getAdds(url){
    setDisable(true);
    publicServices.getAddsRouting(url,tipoSelected,subtipoSelected,estadoSelected,municipioSelected,order,valueSearch)
    .then(response => {
      if (response.status===200) {
        setAdds(response.data.data);
        setMeta(response.data.meta);
        setDisable(false);
      }
    })
    .catch(function (error) {
      return error;
    })
  }

  function getAddsCleanFilter(url){
    setDisable(true);
    publicServices.getAddsRouting(url,[],[],[],[],order,valueSearch)
    .then(response => {
      if (response.status===200) {
        setAdds(response.data.data);
        setMeta(response.data.meta);
        setDisable(false);
      }
    })
    .catch(function (error) {
      return error;
    })
  }

  const onCheckboxEstado = (selected) => {
    const index = estadoSelected.indexOf(selected);
    if (index < 0) {
      estadoSelected.push(selected);
    } else {
      estadoSelected.splice(index, 1);
    }
    setEstadoSelected([...estadoSelected]);
    getMunicipiosEnUso_(estadoSelected);
  }

  const onCheckboxMunicipio = (selected) => {
    const index = municipioSelected.indexOf(selected);
    if (index < 0) {
      municipioSelected.push(selected);
    } else {
      municipioSelected.splice(index, 1);
    }
    setMunicipioSelected([...municipioSelected]);
  }


  const onCheckboxBtnClick = (selected) => {
    const index = tipoSelected.indexOf(selected);
    if (index < 0) {
      tipoSelected.push(selected);
    } else {
      tipoSelected.splice(index, 1);
    }
    setTipoSelected([...tipoSelected]);
    getMultiSubtipos_(tipoSelected);
  }

  const onCheckboxBtnClick2 = (selected) => {
    const index = subtipoSelected.indexOf(selected);
    if (index < 0) {
      subtipoSelected.push(selected);
    } else {
      subtipoSelected.splice(index, 1);
    }
    setSubtipoSelected([...subtipoSelected]);
  }

  const handleChangeOrder = (event) => {
    setOrder(event.target.value);
  };

  function filter(){
    getAdds(null);
    console.log('tipoSelected==>');
    console.log(tipoSelected);
    console.log('subtipoSelected==>');
    console.log(subtipoSelected);
    console.log('estadoSelected==>');
    console.log(estadoSelected);
    console.log('municipioSelected==>');
    console.log(municipioSelected);
  }

  function cleanFilters(e){
    e.preventDefault();
    uncheckElements();
    setTipoSelected([]);
    setSubtipoSelected([]);
    setEstadoSelected([]);
    setMunicipioSelected([]);
    setSubtipos(null);
    setMunicipios(null);
    getAddsCleanFilter(null);
  }

  function uncheckElements(){
    var uncheck=document.getElementsByTagName('input');
    for(var i=0;i<uncheck.length;i++){
      if(uncheck[i].type==='checkbox'){
        uncheck[i].checked=false;
      }
    }
  }

  function Items(props){
    let adds_ =  props.propAdds;
    if (adds_!==null && adds_.length>0) {
      const listItems = adds_.map((add) => {
        let default_image='';
        add.imagenes.forEach(function(img) {
          if (img.principal===1) {
            default_image=img.url_thumb;
          }
        });
        if (default_image==='') {
          default_image=add.imagenes[0]!==undefined ? add.imagenes[0].url_thumb : image_default;
        }
        return (
          <Col xs="12" sm="6" md="6" lg="4" className="p-0 mb-4 text-center" key={add.id}>
            <RouterLink to={'/anuncio/'+add.id} className="item_add sm">
              <img width="100%" src={default_image} alt={add.titulo} />
              <span className="overlay"></span>
              <span className="thumb-info-title text-left">
                <span className="thumb-info-inner">
                  {add.titulo}
                  <small className="block_ctrl">{add.municipio.nombre==add.estado.nombre?add.estado.nombre:add.municipio.nombre+' '+add.estado.nombre}</small></span>
                <span className="thumb-info-type">{add.tipo_anuncio.nombre}</span>
              </span>
              <span className="thumb-info-action">
                <span className="thumb-info-action-icon">
                  <img src={plus} alt=""/>
                </span>
              </span>
            </RouterLink>
          </Col>
          )
      });
      return(listItems);
    }
    return(
      <Col xs="12" className="text-center py-4">No hay resultados</Col>
    );
  }

  function PaginationComponent(props){
    let meta_ = props.propMeta;
    if (meta_!==null && meta.total>parseInt(meta.per_page)) {
      let c_ = 0;
      const pages = meta_.links.map((item) => {
        let label = '';
        c_++;
        if (item.label==='Next &raquo;') {
          label = <i className="fa fa-chevron-right" aria-hidden="true"></i>;
        }else if (item.label==='&laquo; Previous') {
          label = <i className="fa fa-chevron-left" aria-hidden="true"></i>;
        }else{
          label = item.label;
        }
        return (
          <PaginationItem active={item.active} key={c_} disabled={item.url===null?true:false}>
            <PaginationLink onClick={(e) => {getAdds(item.url);}}>
              {label}
            </PaginationLink>
          </PaginationItem>
        )
      });
      return(
        <Pagination aria-label="Page navigation example">
          {pages}
        </Pagination>
      );
    }
    return('');

  }

  return (
      <React.Fragment>

        <Container fluid={true} className="topM" style={{backgroundImage: `url(${topHeader})`}}>
          <div className="overlay"></div>
          <Container className="pt-5 pb-3">
            <Row>
              <Col xs="12" className="text-left">
                <h2 className="fw_600 light">Espacios publicitarios</h2>
              </Col>
              <Col xs="12" className="text-left">
                <Breadcrumb tag="nav" listTag="div" className="cs_01">
                  <BreadcrumbItem to="/" component={RouterLink} tag={RouterLink}>
                    <i className="fa fa-home" aria-hidden="true"></i>
                  </BreadcrumbItem>
                  <BreadcrumbItem to="/espacios-publicitarios" component={RouterLink} tag={RouterLink}>Catalogo</BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container className="container-lg py-7">
          <Row>
            <Col xs="12" md="4" lg="3">
              <Row className="w-100 m-0">
                <Col xs="12" className="p-0">
                  <h4 className="m-0">BUSQUEDA</h4>
                </Col>
                <Col xs="12" className="p-0 mb-3 divider"></Col>
                <Col xs="12" className="p-0 pb-3">
                  <Formik
                    initialValues={{
                      search: ''
                    }}
                    validationSchema={Yup.object().shape({
                      search: Yup.string().max(100),
                    })}
                    onSubmit={(values) => {
                      setDisable(true);
                      setValueSearch(values.search)
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit}>
                          <InputGroup>
                            <Input
                              placeholder="Busqueda"
                              type="text"
                              name="search"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.search}
                              invalid={Boolean(touched.search && errors.search)}
                            />
                            <InputGroupAddon addonType="prepend">
                              {valueSearch!==null && valueSearch!=='' ?
                                <Button color="primary"
                                  size="md"
                                  type="button"
                                  onClick={(e) => {setValueSearch(null);setFieldValue('search', '');}}
                                >
                                  <i class="fa fa-times" aria-hidden="true"></i>
                                </Button>
                              :
                                <Button color="primary"
                                  size="md"
                                  type="submit"
                                  disabled={disable}
                                >
                                  <i class="fa fa-search" aria-hidden="true"></i>
                                </Button>
                              }
                            </InputGroupAddon>
                            {errors.search && touched.search ? (
                              <div className="invalid-feedback d-block">{errors.search}</div>
                            ) : null}
                          </InputGroup>
                      </form>
                    )}
                  </Formik>
                </Col>
                <Col xs="12" className="p-0">
                  <h4 className="m-0">ORDENAR POR</h4>
                </Col>
                <Col xs="12" className="p-0 mb-3 divider"></Col>
                <Col xs="12" className="p-0 pb-3">
                  <FormGroup>
                    <Input type="select" onChange={(e) => {handleChangeOrder(e);}} defaultValue="">
                      <option hidden value=""></option>
                      <option value="nombre az">Nombre: A - Z</option>
                      <option value="nombre za">Nombre: Z - A</option>
                      <option value="Lo mas nuevo">Lo más nuevo primero</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col xs="12" className="p-0">
                  <h4 className="m-0">FILTROS</h4>
                </Col>
                <Col xs="12" className="p-0 mb-3 divider"></Col>
                {tipoSelected.length>0 || subtipoSelected >0 || estadoSelected >0 || municipioSelected >0 ?
                  <Col xs="12" className="p-0 pb-3 inline_tipos">
                    <Button href="#" onClick={(e) => cleanFilters(e)}><i className="fa fa-times" aria-hidden="true"></i>{' '}Limpiar filtros</Button>
                  </Col>
                :''}
                <Col xs="12" className="p-0 pb-3 inline_tipos">
                  {estados!==null && estados.length>0 ?
                    <React.Fragment>
                      <Row className="m-0 w-100">
                        <Col xs="12" className="p-0">
                          <h6 className="fw_600">Estados</h6>
                        </Col>
                      </Row>
                      {estados.map((estado) => 
                        {
                          return(
                            <FormGroup check key={estado.id}>
                              <Label check>
                                {estado.nombre}{' '}
                                <Input type="checkbox" value={estado.id} onClick={() => onCheckboxEstado(estado.id)}/>
                              </Label>
                            </FormGroup>
                          );
                        }
                      )}
                    </React.Fragment>
                  :''}
                </Col>
                <Col xs="12" className="p-0 pb-3 inline_tipos">
                  {municipios!==null && municipios.length>0 ?
                    <React.Fragment>
                      <Row className="m-0 w-100">
                        <Col xs="12" className="p-0">
                          <h6 className="fw_600">Municipios</h6>
                        </Col>
                      </Row>
                      {municipios.map((municipio) => 
                        {
                          return(
                            <FormGroup check key={municipio.id}>
                              <Label check>
                                {municipio.nombre}{' '}
                                <Input type="checkbox" value={municipio.id} onClick={() => onCheckboxMunicipio(municipio.id)}/>
                              </Label>
                            </FormGroup>
                          );
                        }
                      )}
                    </React.Fragment>
                  :''}
                </Col>
                <Col xs="12" className="p-0 mb-3 divider grey"></Col>
                <Col xs="12" className="p-0 pb-3 inline_tipos">
                  {tipos!==null && tipos.length>0 ?
                    <React.Fragment>
                      <Row className="m-0 w-100">
                        <Col xs="12" className="p-0">
                          <h6 className="fw_600">Tipos</h6>
                        </Col>
                      </Row>
                      {tipos.map((tipo) => 
                        {
                          return(
                            <FormGroup check key={tipo.id}>
                              <Label check>
                                {tipo.nombre}{' '}
                                { tipo_p!==undefined && parseInt(tipo_p)===parseInt(tipo.id) ? 
                                  <Input type="checkbox" defaultChecked value={tipo.id} onClick={() => onCheckboxBtnClick(tipo.id)}/>
                                :
                                  <Input type="checkbox" value={tipo.id} onClick={() => onCheckboxBtnClick(tipo.id)}/>
                                }
                              </Label>
                            </FormGroup>
                          );
                        }
                      )}
                    </React.Fragment>
                  :''}
                </Col>
                <Col xs="12" className="p-0 pb-3 inline_tipos">
                  {subtipos!==null && subtipos.length>0 ?
                    <React.Fragment>
                      <Row className="m-0 w-100">
                        <Col xs="12" className="p-0">
                          <h6 className="fw_600">Subtipos</h6>
                        </Col>
                      </Row>
                      {subtipos.map((subtipo) => 
                        {
                          return(
                            <FormGroup check key={subtipo.id}>
                              <Label check>
                                {subtipo.nombre}{' '}
                                { subtipo_p!==undefined && parseInt(subtipo_p)===parseInt(subtipo.id) ? 
                                  <Input type="checkbox" defaultChecked value={subtipo.id} onClick={() => onCheckboxBtnClick2(subtipo.id)}/>
                                :
                                  <Input type="checkbox" value={subtipo.id} onClick={() => onCheckboxBtnClick2(subtipo.id)}/>
                                }
                              </Label>
                            </FormGroup>
                          );
                        }
                      )}
                    </React.Fragment>
                  :''}
                </Col>
                <Col xs="12" className="p-0 pb-3">
                    <Button color="primary" className="my-2 fw_600" onClick={() => { filter(); }}>
                      <i className="fa fa-filter" aria-hidden="true"></i> Aplicar filtro
                    </Button>
                </Col>
              </Row>
            </Col>
            <Col xs="12" md="8" lg="9">
              <Row className="m-0">
                <Items propAdds={adds}/>
              </Row>
              <Row className="m-0">
                <PaginationComponent propMeta={meta}/>
              </Row>
              {disable?
                <div className="absoluteLoader">
                  <Spinner color="primary" />
                </div>
              :''}
            </Col>
          </Row>
        </Container>

      </React.Fragment>
  );
};

export default EspaciosPublicitarios;

import React, { useState, useEffect } from 'react';

const Comodin = (props) => {

  const setActiveTab = props.propSetActiveTab;
  const returnTo = props.propReturn;

  useEffect(() => {
    setActiveTab(returnTo);
  }, []);

  return (
    <React.Fragment>

    </React.Fragment>
  );
};

export default Comodin;

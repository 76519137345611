import React from 'react';
import {
  Box,
  Container,
  makeStyles,
  Card,
  CardHeader
} from '@material-ui/core';
import Page from 'src/components/Page';
import MapWithCluster from '../ui/MapWithCluster';
const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY_MAPS}&v=3.exp&libraries=geometry,drawing,places`;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Anuncios = () => {

  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Mapa"
    >
      <Container maxWidth={false}>
      <Box mt={3}>
        <Card>
          <CardHeader
            title="Mis anuncios en el mapa"
          />
          <MapWithCluster
            googleMapURL={mapUrl}
            loadingElement={<span>Cargando</span>}
            containerElement={<div style={{ height: '650px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            zoom={7}
            public={false}
          />
        </Card>
      </Box>
      </Container>
    </Page>
  );
};

export default Anuncios;

import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Link,
  Button,
  ButtonGroup,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Trash2 as TrashIcon, Edit3 as EditIcon } from 'react-feather';
import Toolbar from './Toolbar';
import { sellerServices } from '../../services/sellerServices';
import image_default from '../../icons/image_default.svg';
const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));
Yup.setLocale({
  mixed: {
    required: 'Esta campo es requerido',
  },
  string: {
    required: 'Esta campo es requerido',
    max: 'El texto es demasiado largo',
    min: 'El texto es demasiado corto',
  },
  number: {
    integer: 'Solo se aceptan datos numericos'
  }
})

const Results = ({ className, ...rest }) => {
  const classes = useStyles();
  const [selectedAddsIds, setSelectedAddsIds] = useState([]);
  const [page, setPage] = useState(0);
  const [specs, setSpecs] = useState(null);
  const [spec, setSpec] = useState([]);
  const [tiposAnuncio, setTiposAnuncio] = useState([]);
  
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [openConfirm, setOpenConfirm] = useState(false);
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const [openCreate, setOpenCreate] = useState(false);
  const handleCloseCreate = () => {
    setOpenCreate(false);
  };
  const handleOpenCreate = () => {
    if (tiposAnuncio.length===0) {
      getTiposAnuncio();
    }
    setOpenCreate(true);
  };

  useEffect(() => {
      getSpecs(1);
  }, []);

  function getSpecs(page){
      sellerServices.getSpecs(page, 5)
      .then(response => {
        if (response.status===200) {
          setSpecs(response.data);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  function getTiposAnuncio(){
    sellerServices.getTiposAnuncio()
    .then(response => {
      setTiposAnuncio(response.data);
    })
    .catch(function (error) {
        return error;
    })
  }

  const handleSelectAll = (event) => {
    let newSelectedAddsIds;

    if (event.target.checked) {
      newSelectedAddsIds = specs.data.map((spec) => spec.id);
    } else {
      newSelectedAddsIds = [];
    }
    setSelectedAddsIds(newSelectedAddsIds);
    console.log('newSelectedAddsIds==>')
    console.log(newSelectedAddsIds)
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedAddsIds.indexOf(id);
    let newSelectedAddsIds = [];
    if (selectedIndex === -1) {
      newSelectedAddsIds = newSelectedAddsIds.concat(selectedAddsIds, id);
    } else if (selectedIndex === 0) {
      newSelectedAddsIds = newSelectedAddsIds.concat(selectedAddsIds.slice(1));
    } else if (selectedIndex === selectedAddsIds.length - 1) {
      newSelectedAddsIds = newSelectedAddsIds.concat(selectedAddsIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedAddsIds = newSelectedAddsIds.concat(
        selectedAddsIds.slice(0, selectedIndex),
        selectedAddsIds.slice(selectedIndex + 1)
      );
    }
    setSelectedAddsIds(newSelectedAddsIds);
  };

  const handlePageChange = (event, newPage) => {
    getSpecs(newPage+1);
  };

  const deleteItemSelected = (items) => {
    if (items!==null) {
      setSelectedAddsIds(items);
    }
    setOpenConfirm(true);
  };

  const handleDelete = () => {
    sellerServices.deleteSpecs(selectedAddsIds)
    .then(response => {
      if (response.status===204) {
        getSpecs(page);
        setOpenConfirm(false);
      }
    })
    .catch(function (error) {
        return error;
    })
  };

  const handleOpenUpdate = (spec) => {
    if (tiposAnuncio.length===0) {
      getTiposAnuncio();
    }
    setSpec(spec);
    setOpen(true);
  };

  return (
    <React.Fragment>
      <React.Fragment>
      <Toolbar propOpenCreate={handleOpenCreate} />
      {specs!==null && specs.data.length>0 ? 
      <Box mt={3}>
        <Card
          className={clsx(classes.root, className)}
          {...rest}
        >
          <React.Fragment>
            <PerfectScrollbar>
              <Box minWidth={1050}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedAddsIds.length === specs.data.length}
                          color="primary"
                          indeterminate={
                            selectedAddsIds.length > 0
                            && selectedAddsIds.length < specs.data.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell>
                        #
                      </TableCell>
                      <TableCell>
                        Nombre
                      </TableCell>
                      <TableCell>
                        Pertenece a
                      </TableCell>
                      <TableCell>
                        
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {specs.data.map((spec) => (
                      <TableRow
                        hover
                        key={spec.id}
                        selected={selectedAddsIds.indexOf(spec.id) !== -1}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedAddsIds.indexOf(spec.id) !== -1}
                            onChange={(event) => handleSelectOne(event, spec.id)}
                            value="true"
                          />
                        </TableCell>
                        <TableCell>
                          {spec.id}
                        </TableCell>
                        <TableCell>
                          {spec.nombre}
                        </TableCell>
                        <TableCell>
                          {spec.tipo.nombre}
                        </TableCell>
                        <TableCell>
                          <ButtonGroup size="small" aria-label="small outlined button group">
                            <Button
                              onClick={(e) => {e.preventDefault();deleteItemSelected([spec.id]);}}
                            >
                              <SvgIcon
                                fontSize="small"
                                color="action"
                              >
                                <TrashIcon />
                              </SvgIcon>
                            </Button>
                            <Button
                              onClick={(e) => {e.preventDefault();handleOpenUpdate(spec);}}
                            >
                              <SvgIcon
                                fontSize="small"
                                color="action"
                              >
                                <EditIcon />
                              </SvgIcon>
                            </Button>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {selectedAddsIds.length>0 ?
                  <div className="block_ctrl" style={{padding: "15px", textAlign: "right", }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(e) => {e.preventDefault();deleteItemSelected(null);}}
                    >
                      Eliminar
                    </Button>
                  </div>
                :''}
              </Box>
            </PerfectScrollbar>
            <TablePagination
              component="div"
              count={specs.meta.total}
              onChangePage={handlePageChange}
              page={specs.meta.current_page-1}
              rowsPerPage={specs.meta.per_page}
              rowsPerPageOptions={false}
            />
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth="true" aria-labelledby="form-dialog-title">
              <Formik
                initialValues={{
                  id: spec.id,
                  nombre: spec.nombre,
                  nombre_corto: spec.nombre_corto,
                  tipo_anuncio_id: spec.tipo!==undefined ? spec.tipo.id : '' 
                }}
                validationSchema={Yup.object().shape({
                  nombre: Yup.string().required(),
                  nombre_corto: Yup.string().required(),
                  tipo_anuncio_id: Yup.string().required()
                })}
                onSubmit={(values) => {
                  sellerServices.updateSpec(values)
                  .then(response => {
                    if (response.status===200) {
                      getSpecs(page);
                      setOpen(false);
                    }
                  })
                  .catch(function (error) {
                      return error;
                  })
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form
                    autoComplete="off"
                    onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}
                    style={{
                      display: 'inline-block',
                      width: '100%',
                    }}
                  >
                    <DialogTitle id="form-dialog-title">Edita la característica</DialogTitle>
                    <DialogContent>
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            name="nombre"
                            onChange={(e) => {handleChange(e); }}
                            required
                            error={Boolean(touched.nombre && errors.nombre)}
                            helperText={touched.nombre && errors.nombre}
                            onBlur={handleBlur}
                            value={values.nombre}
                            variant="outlined"
                            label="Nombre"
                            type="text"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            name="nombre_corto"
                            onChange={(e) => {handleChange(e); }}
                            required
                            error={Boolean(touched.nombre_corto && errors.nombre_corto)}
                            helperText={touched.nombre_corto && errors.nombre_corto}
                            onBlur={handleBlur}
                            value={values.nombre_corto}
                            variant="outlined"
                            label="Nombre corto"
                            type="text"
                          />
                        </Grid>
                        {tiposAnuncio.length>0 ?
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Tipo de anuncio"
                              name="tipo_anuncio_id"
                              onChange={(e) => {handleChange(e);}}
                              required
                              select
                              SelectProps={{ native: true }}
                              error={Boolean(touched.tipo_anuncio_id && errors.tipo_anuncio_id)}
                              helperText={touched.tipo_anuncio_id && errors.tipo_anuncio_id}
                              onBlur={handleBlur}
                              value={values.tipo_anuncio_id}
                              variant="outlined"
                            >
                              <option hidden
                                value=''
                              ></option>
                              {tiposAnuncio.map((tipo) => (
                                <option
                                  key={tipo.id}
                                  value={tipo.id}
                                >{tipo.nombre}</option>
                              ))}
                            </TextField>
                          </Grid>
                        :''}
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="default">
                        Cancelar
                      </Button>
                      <Button type="submit" color="primary">
                        Cambiar
                      </Button>
                    </DialogActions>
                  </form>
                )}
              </Formik>
            </Dialog>
            <Dialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{selectedAddsIds.length>1?'Estas a punto de eliminar multiples características.':'Estas a punto de eliminar una característica.'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">¿Deseas continuar?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseConfirm} color="primary">
                  Cancelar
                </Button>
                <Button onClick={handleDelete} color="primary" autoFocus>
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        </Card>
      </Box>
      : ''}
            <Dialog open={openCreate} onClose={handleCloseCreate} maxWidth='sm' fullWidth="true" aria-labelledby="form-dialog-title">
              <Formik
                initialValues={{
                  nombre: '',
                  nombre_corto: '',
                  tipo_anuncio_id: ''
                }}
                validationSchema={Yup.object().shape({
                  nombre: Yup.string().required(),
                  nombre_corto: Yup.string().required(),
                  tipo_anuncio_id: Yup.string().required()
                })}
                onSubmit={(values) => {
                  sellerServices.addSpec(values)
                  .then(response => {
                    if (response.status===201) {
                      getSpecs(page);
                      setOpenCreate(false);
                    }
                  })
                  .catch(function (error) {
                      return error;
                  })
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form
                    autoComplete="off"
                    onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}
                    style={{
                      display: 'inline-block',
                      width: '100%',
                    }}
                  >
                    <DialogTitle id="form-dialog-title">Agrega una nueva característica</DialogTitle>
                    <DialogContent>
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            name="nombre"
                            onChange={(e) => {handleChange(e); }}
                            required
                            error={Boolean(touched.nombre && errors.nombre)}
                            helperText={touched.nombre && errors.nombre}
                            onBlur={handleBlur}
                            value={values.nombre}
                            variant="outlined"
                            label="Nombre"
                            type="text"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            name="nombre_corto"
                            onChange={(e) => {handleChange(e); }}
                            required
                            error={Boolean(touched.nombre_corto && errors.nombre_corto)}
                            helperText={touched.nombre_corto && errors.nombre_corto}
                            onBlur={handleBlur}
                            value={values.nombre_corto}
                            variant="outlined"
                            label="Nombre corto"
                            type="text"
                          />
                        </Grid>

                        {tiposAnuncio.length>0 ?
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Tipo de anuncio"
                              name="tipo_anuncio_id"
                              onChange={(e) => {handleChange(e);}}
                              required
                              select
                              SelectProps={{ native: true }}
                              error={Boolean(touched.tipo_anuncio_id && errors.tipo_anuncio_id)}
                              helperText={touched.tipo_anuncio_id && errors.tipo_anuncio_id}
                              onBlur={handleBlur}
                              value={values.tipo_anuncio_id}
                              variant="outlined"
                            >
                              <option hidden
                                value=''
                              ></option>
                              {tiposAnuncio.map((tipo) => (
                                <option
                                  key={tipo.id}
                                  value={tipo.id}
                                >{tipo.nombre}</option>
                              ))}
                            </TextField>
                          </Grid>
                        :''}

                      </Grid>

                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseCreate} color="default">
                        Cancelar
                      </Button>
                      <Button type="submit" color="primary">
                        Guardar
                      </Button>
                    </DialogActions>
                  </form>
                )}
              </Formik>
            </Dialog>
      </React.Fragment>
    
    </React.Fragment>
  );

};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  LinearProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from '@material-ui/core';

import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ImageIcon from '@material-ui/icons/Image';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import { sellerServices } from '../../../services/sellerServices';
import Geocode from "react-geocode";
import MapWithAMarker from '../../ui/Map'
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useDropzone} from 'react-dropzone';
import { axiosApiInstanceFormData } from '../../../services/ManageToken'
import { showToastr, toastrError } from '../../../utils/toast';

Yup.setLocale({
  mixed: {
    required: 'Esta campo es requerido',
  },
  string: {
    required: 'Esta campo es requerido',
    max: 'El texto es demasiado largo',
    min: 'El texto es demasiado corto',
  },
  number: {
    integer: 'Solo se aceptan datos numericos'
  }
})

const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY_MAPS}&v=3.exp&libraries=geometry,drawing,places`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  rootcard: {
    width: '100%'
  },
  mediacard: {
    height: 150,
  },
  buttonCard: {
    display: 'inline-block',
    position: 'relative',
    float: 'right',
    padding: '0px',
    margin: '0px',
    top: '5px',
    right: '5px'
  },
  svgButtonCard: {
    color: '#34548b'
  }
}));

Geocode.setApiKey(`${process.env.REACT_APP_API_KEY_MAPS}`,);
Geocode.setLanguage("es");
Geocode.setRegion("es");

const FormAdd = ({ className, ...rest }, props) => {

  const navigate = useNavigate();

  const classes = useStyles();

  const [disable, setDisable] = useState(false);

  /*Upload images*/
  
  const dropzone_style = {
    borderRadius: 2,
    border: '2px dashed #eaeaea',
    padding: '25px 0px',
    textAlign: 'center'
  };

  const [files, setFiles] = useState([]);

  function sizeValidator(file) {
    if (file.size > process.env.REACT_APP_IMG_SIZE_BY_ADD*1024*1024) {
      return {
        code: "name-too-large",
        message: `El peso supera los ${process.env.REACT_APP_IMG_SIZE_BY_ADD} mb permitidos.`
      };
    }
    return null
  }

  const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({
    maxFiles: process.env.REACT_APP_IMGS_BY_ADD,
    accept: 'image/jpeg, image/png',
    validator: sizeValidator,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });
  
  const removeFile = (file) => () => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  }

  const thumbs = files.map(file => (
    <Grid
      item
      xs={6}
      md={4}
      lg={3}
      xl={2}
      key={file.size}
    >                                
      <Card className={classes.rootcard}>
        <CardMedia
          image={file.preview}
          className={classes.mediacard}
        >
          <button className={classes.buttonCard} onClick={removeFile(file)}>
            <DeleteForeverIcon className={classes.svgButtonCard} />
          </button>
        </CardMedia>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p" align="center">{file.name}</Typography>
          <Typography variant="body2" color="textSecondary" component="p" align="center">{Number(((file.size/1024)/1024).toFixed(2))} mb</Typography>
        </CardContent>
      </Card>
    </Grid>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <List key={file.size}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={file.path+' - '+Number(((file.size/1024)/1024).toFixed(2))+' mb'}/>
      </ListItem>
      <ListItem>
      {errors.map(e => (
        <small key={e.code} style={{ display: 'block' }}>{e.message}</small>
      ))}
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const [tiposAnuncio, setTiposAnuncio] = useState(null);
  const [subtiposAnuncio, setSubtiposAnuncio] = useState(null);
  const [estados, setEstados] = useState(null);
  const [municipios, setMunicipios] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [progress, setProgress] = useState(null);

  function ProgressBar(props){
    let progress_ = props.propProgress;
    if (props.propProgress!==null) {
      return(
        <div style={{
              display: 'inline-block',
              position: 'absolute',
              left: '0px',
              right: '0px',
              top: '0px',
              bottom: '0px',
              width: '100%',
              height: '100%',
              backgroundColor: '#FFFFFF',
              zIndex: '1'
        }}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              height: '100%'
            }}
          >
            <Grid
              item
              xl={7}
              lg={7}
              md={9}
              sm={11}
              xs={11}
            >
              <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress variant="determinate" value={progress_} />
                </Box>
                <Box minWidth={35}>
                  <Typography variant="body2" color="textSecondary">{progress_}%</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      );
    }
    return('');
  }

  function getSteps() {
    return ['Completa la información', 'Verifica la ubicación', 'Agrega fotografias'];
  }

  const steps = getSteps();

  const handleNext = () => {
    if (activeStep===0) {
      getCoord();
    }
    setActiveStep(activeStep+1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getCoord(){
    if (vals.enCarretera==='no') {
      var address='Calle '+vals.calle+' #'+vals.numero+' Col. '+vals.colonia+'. '+vals.municipio_nombre+', '+vals.estado_nombre;
    }else{
      var address='Carretera '+vals.carretera+', km.'+vals.kilometro+'. '+vals.municipio_nombre+', '+vals.estado_nombre;
    }
    console.log('address==>');
    console.log(address);
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setVals({
          ...vals,
          ['latitud']: lat,
          ['longitud']: lng
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  useEffect(() => {
    getTiposAnuncio(1);
    getEstados_(1);
  }, []);

  function getTiposAnuncio(){
    sellerServices.getTiposAnuncio()
    .then(response => {
      setTiposAnuncio(response.data);
    })
    .catch(function (error) {
        return error;
    })
  }

  function getSubtiposAnuncio(tipo_anuncio_id){
    sellerServices.getSubtiposAnuncio(tipo_anuncio_id)
    .then(response => {
      setSubtiposAnuncio(response.data);
    })
    .catch(function (error) {
        return error;
    })
  }

  function getEstados_(){
    sellerServices.getEstados()
    .then(response => {
      setEstados(response.data);
    })
    .catch(function (error) {
        return error;
    })
  }

  function getMunicipiosEstado_(estado_id){
    sellerServices.getMunicipiosEstado(estado_id)
    .then(response => {
      setMunicipios(response.data);
    })
    .catch(function (error) {
        return error;
    })
  }

  const [vals, setVals] = useState({
    titulo: '',
    descripcion_corta: '',
    descripcion_larga: '',
    colonia: '',
    calle: '',
    numero: '',
    carretera: '',
    kilometro: '',
    largo: '',
    ancho: '',
    latitud: '',
    longitud: '',
    target: '',
    concurrencia_diaria: '',
    tipo_anuncio_id: '',
    subtipo_anuncio_id: '',
    estado_id: '',
    municipio_id: '',
    estado_nombre: '',
    municipio_nombre: '',
    enCarretera: 'no'
  });

  const handleChangeState = (event) => {
    setVals({
      ...vals,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeGetSubtiposAnuncio = (event) => {
    setVals({
      ...vals,
      [event.target.name]: event.target.value
    });
    getSubtiposAnuncio(event.target.value);
  };

  const handleChangeGetMunicipios = (event) => {
    estados.forEach(function(d1) {
      if (parseInt(d1.id)===parseInt(event.target.value)) {
        setVals({
          ...vals,
          ['estado_nombre']: d1.nombre,
          ['estado_id']: d1.id
        });
      }
    });
    getMunicipiosEstado_(event.target.value);
  };

  const handleChangeSetMunicipio = (event) => {
    municipios.forEach(function(d1) {
      if (parseInt(d1.id)===parseInt(event.target.value)) {
        setVals({
          ...vals,
          ['municipio_nombre']: d1.nombre,
          ['municipio_id']: d1.id
        });
      }
    });
  };

  const target = ['AAA', 'AA', 'A'];

  function FieldTipoAnuncio(props){
      const handleBlur=props.propHandleBlur;
      const tiposAnuncio_ = props.propTiposAnuncio;
      const handleChange=props.propHandleChange;
      const touched=props.propTouched;
      const errors=props.propErrors;
      const values=props.propValues;
      if (tiposAnuncio_!==null) {
        return(
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Tipo de anuncio"
              name="tipo_anuncio_id"
              onChange={(e) => {handleChange(e);handleChangeGetSubtiposAnuncio(e);}}
              select
              SelectProps={{ native: true }}
              error={Boolean(touched.tipo_anuncio_id && errors.tipo_anuncio_id)}
              helperText={touched.tipo_anuncio_id && errors.tipo_anuncio_id}
              onBlur={handleBlur}
              value={values.tipo_anuncio_id}
              variant="outlined"
            >
              <option hidden
                value=''
              ></option>
              {tiposAnuncio_.map((tipo) => (
                <option
                  key={tipo.id}
                  value={tipo.id}
                >{tipo.nombre}</option>
              ))}
            </TextField>
          </Grid>
        );
      }
      return('');
  }

  function FieldSubtipoAnuncio(props){
      const handleBlur=props.propHandleBlur;
      const subtiposAnuncio_ = props.propSubtiposAnuncio;
      const handleChange=props.propHandleChange;
      const touched=props.propTouched;
      const errors=props.propErrors;
      const values=props.propValues;
      if (subtiposAnuncio_!==null) {
        return(
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Subtipo de anuncio"
              name="subtipo_anuncio_id"
              onChange={(e) => {handleChange(e);handleChangeState(e);}}
              select
              SelectProps={{ native: true }}
              error={Boolean(touched.subtipo_anuncio_id && errors.subtipo_anuncio_id)}
              helperText={touched.subtipo_anuncio_id && errors.subtipo_anuncio_id}
              onBlur={handleBlur}
              value={values.subtipo_anuncio_id}
              variant="outlined"
            >
              <option hidden
                value=''
              ></option>
              {subtiposAnuncio_.map((sub) => (
                <option
                  key={sub.id}
                  value={sub.id}
                >{sub.nombre}</option>
              ))}
            </TextField>
          </Grid>
        );
      }
      return('');
  }

  function FieldEstado(props){
      const handleBlur=props.propHandleBlur;
      const handleChange=props.propHandleChange;
      const touched=props.propTouched;
      const errors=props.propErrors;
      const values=props.propValues;
      const estados_ = props.propEstados;
      if (estados_!==null) {
        return(
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Estado"
              name="estado_id"
              onChange={(e) => {handleChange(e);handleChangeGetMunicipios(e);}}
              select
              SelectProps={{ native: true }}
              error={Boolean(touched.estado_id && errors.estado_id)}
              helperText={touched.estado_id && errors.estado_id}
              onBlur={handleBlur}
              value={values.estado_id}
              variant="outlined"
            >
              <option hidden
                value=''
              ></option>
              {estados_.map((estado_) => (
                <option
                  key={estado_.id}
                  value={estado_.id}
                >{estado_.nombre}</option>
              ))}
            </TextField>
          </Grid>
        );
      }
      return('');
  }

  function FieldMunicipio(props){
      const handleBlur=props.propHandleBlur;
      const handleChange=props.propHandleChange;
      const touched=props.propTouched;
      const errors=props.propErrors;
      const values=props.propValues;
      const municipios_ = props.propMunicipios;
      if (municipios_!==null) {
        return(
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Municipio"
              name="municipio_id"
              onChange={(e) => {handleChange(e);handleChangeSetMunicipio(e);}}
              select
              SelectProps={{ native: true }}
              error={Boolean(touched.municipio_id && errors.municipio_id)}
              helperText={touched.municipio_id && errors.municipio_id}
              onBlur={handleBlur}
              value={values.municipio_id}
              variant="outlined"
            >
              <option hidden
                value=''
              ></option>
              {municipios_.map((muni) => (
                <option
                  key={muni.id}
                  value={muni.id}
                >{muni.nombre}</option>
              ))}
            </TextField>
          </Grid>
        );
      }
      return('');
  }

  function Map(props){
    if (props.propLatitud!=='' && props.propLoingitud!=='') {
      return(
        <MapWithAMarker
          googleMapURL={mapUrl}
          loadingElement={<span>Cargando</span>}
          containerElement={<div style={{ height: '650px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          latitud={parseFloat(vals.latitud)}
          longitud={parseFloat(vals.longitud)}
          iconType={vals.tipo_anuncio_id}
          zoom={16}
        />
      );
    }else{
      return('');
    }
  }

  /*modal custom coords*/
  const [open, setOpen] = useState(false);

  function handleSubmitAdd(){
    setDisable(true);
    const fd = new FormData();
    files.forEach(function(file, index) {
      fd.append("attachment[]", file, file.name);
    });
    Object.entries(vals).forEach(val => {
      fd.append(val[0], val[1]);
    });
    newAdd(fd)
      .then(data => {
        if (data.status === 200) {
          navigate('/vendor/anuncios', { replace: true });
        }
      })
      .catch(function (error) {
      }).then(function () {
        setDisable(false);
      });
  }

  function newAdd(object_data){
    return axiosApiInstanceFormData.post(
        `${process.env.REACT_APP_API_URL}v2/anuncios`,
        object_data,
        {
          onUploadProgress: progressEvent => {
            let prog=Math.round(progressEvent.loaded / progressEvent.total * 100);
            if (prog!==100) {
              setProgress(prog);
            }
          }
        }
      )
      .then(response => {
        if (response.status===200) {
            showToastr('success', 'Correcto.', 'Has publicado un nuevo anuncio.', 2500, true, true, 'top-right');
            setProgress(100);
        }
        return response;
      })
      .catch(function (error) {
        setProgress(null);
        toastrError(error);
      });
  }

  return (
    <React.Fragment>
      <Card style={{
        position: 'relative'
      }}>
        <CardHeader
          title="Nuevo anuncio"
        />
        <Divider />
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep===0 ? 
          <Formik
            initialValues={{
              titulo: vals.titulo,
              descripcion_corta: vals.descripcion_corta,
              descripcion_larga: vals.descripcion_larga,
              colonia: vals.colonia,
              calle: vals.calle,
              numero: vals.numero,
              carretera: vals.carretera,
              kilometro: vals.kilometro,
              largo: vals.largo,
              ancho: vals.ancho,
              latitud: vals.latitud,
              longitud: vals.longitud,
              target: vals.target,
              concurrencia_diaria: vals.concurrencia_diaria,
              tipo_anuncio_id: vals.tipo_anuncio_id,
              subtipo_anuncio_id: vals.subtipo_anuncio_id,
              estado_id_: vals.estado_id_,
              municipio_id: vals.municipio_id,
              enCarretera: vals.enCarretera
            }}
            validationSchema={Yup.object().shape({
              titulo: Yup.string().max(50).required(),
              descripcion_corta: Yup.string().max(250).required(),
              descripcion_larga: Yup.string().max(1000).required(),
              enCarretera: Yup.string(),
              calle: Yup
                .string()
                .max(100)
                .when('enCarretera', {
                  is: 'no',
                  then: Yup.string().required()
                }),
              numero: Yup
                .string()
                .max(7)
                .when('enCarretera', {
                  is: 'no',
                  then: Yup.string().required()
                }),
              colonia: Yup
                .string()
                .max(50)
                .when('enCarretera', {
                  is: 'no',
                  then: Yup.string().required()
                }),
              carretera: Yup
                .string()
                .max(100)
                .when('enCarretera', {
                  is: 'si',
                  then: Yup.string().required()
                }),
              kilometro: Yup
                .string()
                .max(5),
              largo: Yup.number().required().positive().integer().required(),
              ancho: Yup.number().required().positive().integer().required(),
              tipo_anuncio_id: Yup.number().required(),
              subtipo_anuncio_id: Yup.number().required(),
              municipio_id: Yup.number().required()
            })}
            onSubmit={(values) => {
              handleNext();
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                autoComplete="off"
                className={clsx(classes.root, className)}
                onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}
                style={{
                  display: 'inline-block',
                  width: '100%',
                }}
              >
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        color="textSecondary"
                        variant="body1"
                      >
                        Informacion General
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Titulo"
                        name="titulo"
                        onChange={(e) => {handleChange(e); handleChangeState(e);}}
                        error={Boolean(touched.titulo && errors.titulo)}
                        helperText={touched.titulo && errors.titulo}
                        onBlur={handleBlur}
                        value={values.titulo}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Descripción corta"
                        name="descripcion_corta"
                        onChange={(e) => {handleChange(e); handleChangeState(e);}}
                        error={Boolean(touched.descripcion_corta && errors.descripcion_corta)}
                        helperText={touched.descripcion_corta && errors.descripcion_corta}
                        onBlur={handleBlur}
                        value={values.descripcion_corta}
                        variant="outlined"
                        multiline
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Descripción larga"
                        name="descripcion_larga"
                        onChange={(e) => {handleChange(e); handleChangeState(e);}}
                        error={Boolean(touched.descripcion_larga && errors.descripcion_larga)}
                        helperText={touched.descripcion_larga && errors.descripcion_larga}
                        onBlur={handleBlur}
                        value={values.descripcion_larga}
                        variant="outlined"
                        multiline
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        color="textSecondary"
                        variant="body1"
                      >
                        Ubicación
                      </Typography>
                      <Divider />
                    </Grid>



                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="gender"
                          name="enCarretera"
                          onChange={(e) => {handleChange(e); handleChangeState(e);}} 
                          value={values.enCarretera} 
                          error={Boolean(touched.enCarretera && errors.enCarretera)}
                          helperText={touched.enCarretera && errors.enCarretera}
                          onBlur={handleBlur}
                        >
                          <FormControlLabel value="si" control={<Radio color="primary" />} label="En carretera" />
                          <FormControlLabel value="no" control={<Radio color="primary" />} label="En zona urbana" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {vals.enCarretera==='si' ?
                      <React.Fragment>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            label="Carretera"
                            name="carretera"
                            onChange={(e) => {handleChange(e); handleChangeState(e);}}
                            error={Boolean(touched.carretera && errors.carretera)}
                            helperText={touched.carretera && errors.carretera}
                            onBlur={handleBlur}
                            value={values.carretera}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            label="Kilometro"
                            name="kilometro"
                            onChange={(e) => {handleChange(e); handleChangeState(e);}}
                            error={Boolean(touched.kilometro && errors.kilometro)}
                            helperText={touched.kilometro && errors.kilometro}
                            onBlur={handleBlur}
                            value={values.kilometro}
                            variant="outlined"
                          />
                        </Grid>
                      </React.Fragment>
                    :
                      <React.Fragment>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            label="Calle"
                            name="calle"
                            onChange={(e) => {handleChange(e); handleChangeState(e);}}
                            error={Boolean(touched.calle && errors.calle)}
                            helperText={touched.calle && errors.calle}
                            onBlur={handleBlur}
                            value={values.calle}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            label="Número exterior"
                            name="numero"
                            onChange={(e) => {handleChange(e); handleChangeState(e);}}
                            type="number"
                            error={Boolean(touched.numero && errors.numero)}
                            helperText={touched.numero && errors.numero}
                            onBlur={handleBlur}
                            value={values.numero}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            label="Colonia"
                            name="colonia"
                            onChange={(e) => {handleChange(e); handleChangeState(e);}}
                            error={Boolean(touched.colonia && errors.colonia)}
                            helperText={touched.colonia && errors.colonia}
                            onBlur={handleBlur}
                            value={values.colonia}
                            variant="outlined"
                          />
                        </Grid>
                      </React.Fragment>
                    }
                    <FieldEstado
                      propEstados={estados}
                      propHandleChange={handleChange}
                      propTouched={touched}
                      propErrors={errors}
                      propHandleBlur={handleBlur}
                      propValues={values}
                    />
                    <FieldMunicipio
                      propMunicipios={municipios}
                      propHandleChange={handleChange}
                      propTouched={touched}
                      propErrors={errors}
                      propHandleBlur={handleBlur}
                      propValues={values}
                    />
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        color="textSecondary"
                        variant="body1"
                      >
                        Características
                      </Typography>
                      <Divider />
                    </Grid>
                    <FieldTipoAnuncio
                      propTiposAnuncio={tiposAnuncio}
                      propHandleChange={handleChange}
                      propTouched={touched}
                      propErrors={errors}
                      propHandleBlur={handleBlur}
                      propValues={values}
                    />
                    <FieldSubtipoAnuncio
                      propSubtiposAnuncio={subtiposAnuncio}
                      propHandleChange={handleChange}
                      propTouched={touched}
                      propErrors={errors}
                      propHandleBlur={handleBlur}
                      propValues={values}
                    />
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Largo (cm)"
                        name="largo"
                        onChange={(e) => {handleChange(e); handleChangeState(e);}}
                        type="number"
                        error={Boolean(touched.largo && errors.largo)}
                        helperText={touched.largo && errors.largo}
                        onBlur={handleBlur}
                        value={values.largo}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Ancho (cm)"
                        name="ancho"
                        onChange={(e) => {handleChange(e); handleChangeState(e);}}
                        type="number"
                        error={Boolean(touched.ancho && errors.ancho)}
                        helperText={touched.ancho && errors.ancho}
                        onBlur={handleBlur}
                        value={values.ancho}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Target"
                        name="target"
                        onChange={(e) => {handleChange(e); handleChangeState(e);}}
                        select
                        SelectProps={{ native: true }}
                        value={values.target}
                        variant="outlined"
                      >
                        <option hidden
                          value=''
                        ></option>
                        {target.map((option) => (
                          <option
                            key={option}
                            value={option}
                          >
                            {option}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Concurrencia diaria"
                        name="concurrencia_diaria"
                        type="number"
                        onChange={(e) => {handleChange(e); handleChangeState(e);}}
                        value={values.concurrencia_diaria}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          endIcon={<ArrowForwardIcon/>}
                          onClick={(e) => console.log(vals)}
                          type="submit"
                        >
                          Siguiente
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            )}
          </Formik>
        :''}
        {activeStep===1 ?
          <React.Fragment>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Map propLatitud={vals.latitud} propLoingitud={vals.longitud}/>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  sm={4}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                  >
                    <Button
                      variant="contained"
                      color="default"
                      startIcon={<ArrowBackIcon/>}
                      onClick={(e) => handleBack()}
                    >
                      Atras
                    </Button>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={8}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<GpsFixedIcon/>}
                      onClick={(e) => {setOpen(true)}}
                    >
                      Modificar coordenadas
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<ArrowForwardIcon/>}
                      onClick={(e) => handleNext()}
                      style={{ 'marginLeft': '15px' }}
                    >
                      Confirmar ubicación
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </React.Fragment>
        :''}
        {activeStep===2 ?
          <React.Fragment>
            <CardContent>
              <Grid
                container
                spacing={3}
                justify="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={11}
                  {...getRootProps({className: 'dropzone'})} style={dropzone_style}
                >
                  <input {...getInputProps()} />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Agrega hasta {process.env.REACT_APP_IMGS_BY_ADD} imágenes aquí
                  </Typography>
                  <PhotoLibraryIcon style={{ fontSize: '50px', marginTop: '15px' }}/>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={11}
                >
                  <Grid
                    container
                    spacing={3}
                    justify="center"
                    alignItems="center"
                  >
                {thumbs}
              </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  {fileRejectionItems}
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                  >
                    <Button
                      variant="contained"
                      color="default"
                      startIcon={<ArrowBackIcon/>}
                      onClick={(e) => handleBack()}
                    >
                      Atras
                    </Button>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<SaveIcon/>}
                      onClick={(e) => handleSubmitAdd()}
                      disabled={disable}
                    >
                      Guardar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </React.Fragment>
        :''}
        <ProgressBar propProgress={progress}/>
      </Card>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ingresa las coordenadas.</DialogTitle>
        <Formik
          initialValues={{
            latitud: vals.latitud,
            longitud: vals.longitud
          }}
          validationSchema={Yup.object().shape({
            latitud: Yup.string().max(150).required(),
            longitud: Yup.string().max(150).required()
          })}
          onSubmit={(values) => {
            setVals({
              ...vals,
              ['latitud']: values.latitud,
              ['longitud']: values.longitud
            });
            setOpen(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              autoComplete="off"
              className={clsx(classes.root, className)}
              onSubmit={(e) => { e.preventDefault(); handleSubmit(e)}}
            >
              <DialogContent>
                <DialogContentText>
                  No sabes como obtener las coordanadas correctas? Ve un videotutorial {' '}
                  <Link href="https://youtu.be/UHRNeqsdgrI" target="_blank" rel="noreferrer">
                    AQUI
                  </Link>
                </DialogContentText>
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Latitud"
                          name="latitud"
                          onChange={(e) => {handleChange(e)}}
                          error={Boolean(touched.latitud && errors.latitud)}
                          helperText={touched.latitud && errors.latitud}
                          onBlur={handleBlur}
                          value={values.latitud}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Longitud"
                          name="longitud"
                          onChange={(e) => {handleChange(e)}}
                          error={Boolean(touched.longitud && errors.longitud)}
                          helperText={touched.longitud && errors.longitud}
                          onBlur={handleBlur}
                          value={values.longitud}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
              </DialogContent>
              <DialogActions>
                <Button onClick={(e) => {setOpen(false)}} color="primary">
                  Cancelar
                </Button>
                <Button type="submit" color="primary">
                  Guardar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
};

FormAdd.propTypes = {
  className: PropTypes.string
};

export default FormAdd;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  LinearProgress,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio
} from '@material-ui/core';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import ImageIcon from '@material-ui/icons/Image';
import image_default from '../../icons/image_default.svg';
import { axiosApiInstanceFormData } from '../../services/ManageToken'
import { showToastr, toastrError } from '../../utils/toast';
import { makeStyles } from '@material-ui/core/styles';
import RUG from 'react-upload-gallery'
import 'react-upload-gallery/dist/style.css' // or scss
import { sellerServices } from '../../services/sellerServices';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));


const ImageManagerTwo = (props) => {

  const classes = useStyles();

  const add = props.propAdd;
  const setAdd = props.propSetAdd;
  const setActiveTab = props.propSetActiveTab;
  const [disable, setDisable] = useState(false);
  const [open, setOpen] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [imageSelected, setImageSelected] = useState(null);
  const [principalImage, setPrincipalImage] = useState('');

  useEffect(() => {
    if (add!==null) {
      getPrincipalImage(add.imagenes);
    }
  }, [add]);

  function getPrincipalImage(imagenes){
    imagenes.forEach(function(img) {
      if (img.principal===1) {
        setPrincipalImage(img.id);
      }
    });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleClickOpenTwo = () => {
    setOpenTwo(true);
  };

  const handleCloseTwo = () => {
    setOpenTwo(false);
  };


  function customRequest({ uid, file, send, headers, onProgress, onSuccess, onError }) {
    const form_data = new FormData();
    // send file 
    form_data.append('attachment[]', file)
    setDisable(true);
    return axiosApiInstanceFormData.post(
        `${process.env.REACT_APP_API_URL}v2/anuncio/`+add.id+`/imagenes`,
        form_data,
        {
            onUploadProgress: ({ total, loaded }) => {
                onProgress(uid, Math.round(loaded / total * 100));
            }
        }
    )
    .then(response => {
      if (response.status===200) {
        setAdd(response.data.data);
        setActiveTab(40);
        showToastr('success', 'Correcto.', 'Has actualizado tu anuncio.', 2500, true, true, 'top-right');
      }
    }).catch(function (error) {
      toastrError(error);
    }).then(function () {
        setDisable(false);
    });
  }

  function deleteConfirmed() {
    sellerServices.deleteImage(imageSelected.id)
    .then(response => {
      if (response.status===200) {
        setAdd(response.data.data);
        setActiveTab(40);
      }
    })
    .catch(function (error) {
        return error;
    })
  }

  function handleSaveImagenPrincipal(){
    sellerServices.saveImagenPrincipal(add.id,principalImage)
    .then(response => {
      if (response.status===200) {
        setAdd(response.data.data);
        setOpenTwo(false);
      }
    })
    .catch(function (error) {
        return error;
    }) 
  }

  const handleChange = (event) => {
    console.log('event.target.value==>');
    console.log(event.target.value);
    setPrincipalImage(event.target.value);
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            color="textSecondary"
            variant="body1"
          >
            Imagenes
          </Typography>
          <Divider />
        </Grid>
        {add!==null && add.imagenes.length>0 ?
            <Grid
              item
              xs={12}
            >
              <Box
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ImageIcon />}
                  onClick={handleClickOpenTwo}
                >
                  Imagen principal
                </Button>
              </Box>
            </Grid>
        :''}
        <Grid
          item
          xs={12}
        >
          <RUG
            customRequest={customRequest}
            initialState={add.imagenes}
            rules={{
              limit: 10,
              size: 5120,
              width: {
                min: 300,
                max: 3508
              },
              height: {
                min: 150,
                max: 2480
              }
            }}
            accept={['jpg', 'jpeg', 'png']}
            onWarning={(type, rules) => {
              switch(type) {
                case 'accept':
                  showToastr('warning', 'Error.', 'El formato de imagen no es valido.', 2500, true, true, 'top-right');
                case 'limit':
                  showToastr('warning', 'Error.', 'Se aceptan máximo '+rules.limit+' imagenes', 2500, true, true, 'top-right');
                case 'size':
                  showToastr('warning', 'Error.', 'El peso máximo admitido es de '+rules.size/1024+'mb', 2500, true, true, 'top-right');
                case 'minWidth': case 'minHeight':
                  showToastr('warning', 'Error.', 'El tamaño minimo permitido es de '+rules.width.min+'px x '+rules.height.min+'px', 2500, true, true, 'top-right');
                case 'maxWidth': case 'maxHeight':
                  showToastr('warning', 'Error.', 'El tamaño máximo permitido es de '+rules.width.max+'px x '+rules.height.max+'px', 2500, true, true, 'top-right');
                default:
              }
            }}
            onConfirmDelete={(currentImage, images) => {
              setOpen(true);
              setImageSelected(currentImage);
            }}
          />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Eliminar
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Deseas eliminar la imagen seleccionada?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteConfirmed} color="primary">
            Si, eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog onClose={handleCloseTwo} aria-labelledby="customized-dialog-title" open={openTwo} fullWidth={true} maxWidth={'md'}>
        <DialogTitle id="customized-dialog-title" onClose={handleCloseTwo}>
          Selecciona la imagen principal
        </DialogTitle>
        <DialogContent dividers>

          {add!==null && add.imagenes.length>0 ?
              <Grid
                container
                justifyContent="center"
                alignItems="center"
              >
                <ImageList className={classes.imageList} cols={2.5}>
                  {add.imagenes.map((img) => {
                    return(
                      <ImageListItem key={img.id}>
                        <img src={img.url_thumb} alt={img.nombre} />
                        <ImageListItemBar
                          title={img.nombre}
                          classes={{
                            root: classes.titleBar,
                            title: classes.title,
                          }}
                          actionIcon={
                            <Radio
                              checked={principalImage === img.id}
                              onChange={(e) => {setPrincipalImage(img.id)}}
                              value={img.id}
                              name="imagen_principal"
                            />
                          }
                        />
                      </ImageListItem>
                    );
                  }
                  )}
                </ImageList>
              </Grid>
          :''}

        </DialogContent>
        <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveImagenPrincipal}
            >
              Guardar
            </Button>
        </DialogActions>
      </Dialog>


    </React.Fragment>
  );
};

export default ImageManagerTwo;
//{source: 'image url', name: '...'
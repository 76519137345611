import { axiosApiInstanceHeaders } from './ManageToken'
import { showToastr, toastrError } from '../utils/toast';

function getAdd(id){
  return axiosApiInstanceHeaders.get(
      `${process.env.REACT_APP_API_URL}public/anuncios/`+id,
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getSpecsByAdd(id){
  return axiosApiInstanceHeaders.get(
      `${process.env.REACT_APP_API_URL}public/anuncios/specs/`+id,
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function lastAdds(){
  return axiosApiInstanceHeaders.post(
      `${process.env.REACT_APP_API_URL}public/anuncios/ultimos`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getTipos(){
  return axiosApiInstanceHeaders.get(
      `${process.env.REACT_APP_API_URL}public/tipos`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function createUser(object_data){
  return axiosApiInstanceHeaders.post(
      `${process.env.REACT_APP_API_URL}public/users`,
      object_data
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function registerByFacebook(profileObj){
  return axiosApiInstanceHeaders.post(
      `${process.env.REACT_APP_API_URL}public/users`,
      {
          name: profileObj.name,
          email: profileObj.email,
          facebook_id: profileObj.userID,
          type: 'seller'
      }
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Te has registrado correctamente.', 'Ya puedes iniciar sesión.', 3500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function registerByGoogle(profileObj){
  return axiosApiInstanceHeaders.post(
      `${process.env.REACT_APP_API_URL}public/users`,
      {
          name: profileObj.name,
          email: profileObj.email,
          google_id: profileObj.googleId,
          type: 'seller'
      }
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Te has registrado correctamente.', 'Ya puedes iniciar sesión.', 3500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}


function resendActivation(email){
  return axiosApiInstanceHeaders.post(
      `${process.env.REACT_APP_API_URL}public/code/resend/`+email
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'Hemos reenviado el codigo de verificación.', 3500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function activarUsuario(code){
  return axiosApiInstanceHeaders.post(
      `${process.env.REACT_APP_API_URL}public/user/activar/`+code
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Cuenta activada', 'Ya puedes iniciar sesión.', 3500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function generarRecoverLink(object_data){
  return axiosApiInstanceHeaders.post(
      `${process.env.REACT_APP_API_URL}public/user/recoverlink`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Email de reuperación enviado', 'Revisa tu bandeja de entrada.', 3500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    }); 
}

function verifyCodeRecoverPassword(code){
  return axiosApiInstanceHeaders.post(
      `${process.env.REACT_APP_API_URL}public/user/verifyCodeChangePassword/`+code
    )
    .then(response => {
      if (response.status!==200) {
          showToastr('success', 'Link invalido', 'El link ha expirado, solicita otro.', 3500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function cambiarContrasenaPublic(object_data, code){
  return axiosApiInstanceHeaders.post(
      `${process.env.REACT_APP_API_URL}public/user/changePassword/`+code,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Contraseña cambiada', 'Inicia sesión con tu nueva contraseña.', 4500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    }); 
}

function getAdds(page, limit){
  return axiosApiInstanceHeaders.get(
      `${process.env.REACT_APP_API_URL}public/adds`,
      { params: {
          page: page,
          limit: limit
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getAddsRouting(url,tipoSelected,subtipoSelected,estadoSelected,municipioSelected,order,search){
  if (url===null) {
    url=`${process.env.REACT_APP_API_URL}public/adds?page=1`;
  }
  let type_selected=null;
  if (tipoSelected.length>0) {
    type_selected = Object.keys(tipoSelected).map(function(k){return tipoSelected[k]}).join(",");
  }
  let subtype_selected=null;
  if (subtipoSelected.length>0) {
    subtype_selected = Object.keys(subtipoSelected).map(function(k){return subtipoSelected[k]}).join(",");
  }
  let estado_selected=null;
  if (estadoSelected.length>0) {
    estado_selected = Object.keys(estadoSelected).map(function(k){return estadoSelected[k]}).join(",");
  }
  let municipio_selected=null;
  if (municipioSelected.length>0) {
    municipio_selected = Object.keys(municipioSelected).map(function(k){return municipioSelected[k]}).join(",");
  }
  return axiosApiInstanceHeaders.get(
      url,
      { params: {
          limit: 9,
          tipoSelected: type_selected,
          subtipoSelected: subtype_selected,
          estadoSelected: estado_selected,
          municipioSelected: municipio_selected,
          order: order,
          search: search,
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}


function getTiposAnuncio(){
  return axiosApiInstanceHeaders.get(
      `${process.env.REACT_APP_API_URL}public/tipos`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getTiposContentAnuncio(){
  return axiosApiInstanceHeaders.get(
      `${process.env.REACT_APP_API_URL}public/tiposContent`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getMultiSubtipos(tipoSelected){
  let type_selected=null;
  if (tipoSelected.length>0) {
    type_selected = Object.keys(tipoSelected).map(function(k){return tipoSelected[k]}).join(",");
  }
  return axiosApiInstanceHeaders.get(
      `${process.env.REACT_APP_API_URL}public/multisubtipos`,
      { params: {
          tipoSelected: type_selected
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getEstadosEnUso(){
  return axiosApiInstanceHeaders.get(
      `${process.env.REACT_APP_API_URL}public/estadosEnUso`
    )
    .then(response => {
      console.log('response===>');
      console.log(response);
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getMunicipiosEnUsoPorEstado(estadoSelected){
  let type_selected=null;
  if (estadoSelected.length>0) {
    type_selected = Object.keys(estadoSelected).map(function(k){return estadoSelected[k]}).join(",");
  }
  return axiosApiInstanceHeaders.get(
      `${process.env.REACT_APP_API_URL}public/municipiosEnUsoPorEstado`,
      { params: {
          estadoSelected: type_selected
      } }
    )
    .then(response => {
      console.log('response===>');
      console.log(response);
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

async function sendContact(values) {
  return axiosApiInstanceHeaders.post(
    `${process.env.REACT_APP_API_URL}public/contact`,
    values,
  ).then(response => {
    if (response.status === 201) {
      showToastr('success', 'Correcto.', 'Mensaje enviado.', 2500, true, true, 'top-right');
    }else{
      showToastr('error', 'Error.', 'Intentalo mas tarde.', 2500, true, true, 'top-right');
    }
    return response;
  }).catch(function (error) {
    showToastr('error', 'Error.', 'Intentalo mas tarde.', 2500, true, true, 'top-right');
    return Promise.reject(error);
  });
}

export const publicServices = {
  getAdd,
  lastAdds,
  getTipos,
  createUser,
  registerByGoogle,
  registerByFacebook,
  resendActivation,
  activarUsuario,
  generarRecoverLink,
  verifyCodeRecoverPassword,
  cambiarContrasenaPublic,
  getAdds,
  getTiposAnuncio,
  getTiposContentAnuncio,
  getAddsRouting,
  getMultiSubtipos,
  getEstadosEnUso,
  getMunicipiosEnUsoPorEstado,
  sendContact,
  getSpecsByAdd
};

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem
} from '@material-ui/core';

import Calendar from 'rc-year-calendar';
import 'rc-year-calendar/locales/rc-year-calendar.es';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import moment from "moment";
import DeleteIcon from '@material-ui/icons/Delete';
import EventIcon from '@material-ui/icons/Event';
import { sellerServices } from '../../services/sellerServices';
import { Formik } from 'formik';
import * as Yup from 'yup';

registerLocale('es', es)

Yup.setLocale({
  mixed: {
    required: 'Esta campo es requerido',
  },
  string: {
    required: 'Esta campo es requerido',
    max: 'El texto es demasiado largo',
    min: 'El texto es demasiado corto',
  },
  number: {
    integer: 'Solo se aceptan datos numericos'
  }
})

const types_fecha = [
  {
    value: 'apartado',
    label: 'Apartado',
  },
  {
    value: 'pagado',
    label: 'Pagado',
  }
];

const Disponibilidad = (props) => {

  const add = props.propAdd;
  const setAdd = props.propSetAdd;
  let dates_ = [];
  useEffect(() => {
    if (add!==null) {
      getFechasByAdd_(add.id);
    }
  }, [add]);

  const [open, setOpen] = useState(false);
  const [existRange, setExistRange] = useState(false);
  const [dataDates, setDataDates] = useState(null);
  const [datesModal, setDatesModal] = useState(
    {
      start_date: moment().toDate(),
      end_date: moment().add(7, 'days').toDate()
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getFechasByAdd_(id){
      sellerServices.getFechasByAdd(id)
      .then(response => {
        let dates = response.data.data;
        let obj = [];
        dates.forEach(function(value, index) {
            obj[index] = {
              startDate: new Date(value.start_date),
              endDate: new Date(value.end_date)
            }
        });
        console.log('obj==>');
        console.log(obj);
        setDataDates(obj);
        dates_=obj;
      })
      .catch(function (error) {
          return error;
      })
  }

  const setModalVals = (start, end) => {
    setDatesModal(
      {
        start_date: new Date(start),
        end_date: new Date(end)
      }
    );
    console.log('start==>');
    console.log(start);
    console.log('end==>');
    console.log(end);
    dates_.forEach(function(value, index) {
      console.log('value.startDate==>');
      console.log(value.startDate);
      console.log('value.endDate==>');
      console.log(value.endDate);
      if (moment(value.startDate).isSame(start) && moment(value.endDate).isSame(end)) {
        console.log('COINCIDEN+++++++++++++++++++++++++++++');
        setExistRange(true);
      }else{
        console.log('NO CONCIDEN--------');
      }
    });
    setOpen(true);
  };

  return (
    <React.Fragment>
      {add!==null ?
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <Typography
                color="textSecondary"
                variant="body1"
              >
                Disponibilidad
              </Typography>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Calendar
                dataSource={dataDates}
                style='background'
                language='es'
                enableRangeSelection={true}
                enableContextMenu={true}
                onRangeSelected={e => { setExistRange(false);setModalVals(e.startDate, e.endDate); }}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Box
                display="flex"
                pt={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<EventIcon/>}
                  onClick={(e) => {setExistRange(false);setOpen(true)}}
                >
                  Agregar rango de fechas
                </Button>
              </Box>
            </Grid>
          </Grid>
      :''}


            <Dialog className="dialog_datepidker" open={open} onClose={handleClose} maxWidth='sm' aria-labelledby="form-dialog-title">
              <Formik
                initialValues={{
                  start_date: datesModal.start_date,
                  end_date: datesModal.end_date,
                  type: 'pagado',
                }}
                validationSchema={Yup.object().shape({
                  start_date: Yup.string().required().nullable(),
                  end_date: Yup.string().required().nullable(),
                  type: Yup.string().required(),
                })}
                onSubmit={(values) => {
                  console.log(values);
                  if (existRange) {
                    sellerServices.removeFecha(values, add.id)
                    .then(response => {
                      if (response.status===204) {
                        setOpen(false);
                        getFechasByAdd_(add.id);
                      }
                    })
                    .catch(function (error) {
                        return error;
                    })
                  }else{
                    sellerServices.addFecha(values, add.id)
                    .then(response => {
                      if (response.status===201) {
                        setOpen(false);
                        getFechasByAdd_(add.id);
                      }
                    })
                    .catch(function (error) {
                        return error;
                    })
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue
                }) => (
                  <form
                    autoComplete="off"
                    onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}
                    style={{
                      display: 'inline-block',
                      width: '100%',
                    }}
                  >
                    <DialogTitle id="form-dialog-title">Rango de fechas</DialogTitle>
                    <DialogContent>
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          xs={12}
                          className={'datepick_st'}
                        >
                          <label htmlFor="start_date">Fecha de inicio</label>
                          <DatePicker
                            onSelect={thisVal => {setFieldValue("start_date", thisVal);}}
                            selected={values.start_date}
                            //onChange={thisVal => {setFieldValue("start_date", thisVal);}}
                            dateFormat="dd/MM/yyyy"
                            name="start_date"
                            id="start_date"
                            error={Boolean(touched.start_date && errors.start_date)}
                            onBlur={handleBlur}
                            //minDate={moment().toDate()}
                            //maxDate={moment().add(6, 'months').toDate()}
                          />
                          {errors.start_date && touched.start_date ? (
                             <p className="error">{errors.start_date}</p>
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className={'datepick_st'}
                        >
                          <label htmlFor="end_date">Fecha de fin</label>
                          <DatePicker
                            onSelect={thisVal => {setFieldValue("end_date", thisVal);}}
                            selected={values.end_date}
                            //onChange={thisVal => {setFieldValue("end_date", thisVal);}}
                            dateFormat="dd/MM/yyyy"
                            name="end_date"
                            id="end_date"
                            //minDate={moment().toDate()}
                            //maxDate={moment().add(6, 'months').toDate()}
                          />
                          {errors.end_date && touched.end_date ? (
                             <p className="error">{errors.end_date}</p>
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <label htmlFor="type">Tipo</label>
                          <TextField
                            fullWidth
                            name="type"
                            id="type"
                            select
                            onChange={(e) => {handleChange(e); }}
                            error={Boolean(touched.type && errors.type)}
                            helperText={touched.type && errors.type}
                            onBlur={handleBlur}
                            value={values.type}
                            variant="outlined"
                          >
                            {types_fecha.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="default">
                        Cancelar
                      </Button>
                      {existRange ?
                      <Button type="submit" color="primary">
                        Eliminar
                      </Button>
                      :
                      <Button type="submit" color="primary">
                        Agregar
                      </Button>
                      }
                    </DialogActions>
                  </form>
                )}
              </Formik>
            </Dialog>


    </React.Fragment>
  );
};

export default Disponibilidad;

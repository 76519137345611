import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { sellerServices } from '../../services/sellerServices';
import { Formik } from 'formik';
import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'Esta campo es requerido',
  },
  string: {
    required: 'Esta campo es requerido',
    max: 'El texto es demasiado largo',
    min: 'El texto es demasiado corto',
  },
  number: {
    integer: 'Solo se aceptan datos numericos'
  }
})

const InformacionBasica = (props) => {

  const add = props.propAdd;

  useEffect(() => {
    if (add!==null) {
      getTiposAnuncio(1);
      getEstados_(1);
      getMunicipiosEstado_(add.estado.id);
      getSubtiposAnuncio(add.tipo_anuncio.id);
    }
  }, [add]);

  const [tiposAnuncio, setTiposAnuncio] = useState(null);
  const [subtiposAnuncio, setSubtiposAnuncio] = useState(null);
  const [estados, setEstados] = useState(null);
  const [municipios, setMunicipios] = useState(null);

  function getTiposAnuncio(){
    sellerServices.getTiposAnuncio()
    .then(response => {
      setTiposAnuncio(response.data);
    })
    .catch(function (error) {
        return error;
    })
  }

  function getSubtiposAnuncio(tipo_anuncio_id){
    sellerServices.getSubtiposAnuncio(tipo_anuncio_id)
    .then(response => {
      setSubtiposAnuncio(response.data);
    })
    .catch(function (error) {
        return error;
    })
  }

  function getEstados_(){
    sellerServices.getEstados()
    .then(response => {
      setEstados(response.data);
    })
    .catch(function (error) {
        return error;
    })
  }

  function getMunicipiosEstado_(estado_id){
    sellerServices.getMunicipiosEstado(estado_id)
    .then(response => {
      setMunicipios(response.data);
    })
    .catch(function (error) {
        return error;
    })
  }

  const handleChangeGetSubtiposAnuncio = (event) => {
    getSubtiposAnuncio(event.target.value);
  };

  const handleChangeGetMunicipios = (event) => {
    getMunicipiosEstado_(event.target.value);
  };

  const target = ['AAA', 'AA', 'A'];

  function FieldTipoAnuncio(props){
      const handleBlur=props.propHandleBlur;
      const tiposAnuncio_ = props.propTiposAnuncio;
      const handleChange=props.propHandleChange;
      const touched=props.propTouched;
      const errors=props.propErrors;
      const values=props.propValues;
      if (tiposAnuncio_!==null) {
        return(
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Tipo de anuncio"
              name="tipo_anuncio_id"
              onChange={(e) => {handleChange(e);handleChangeGetSubtiposAnuncio(e);}}
              select
              SelectProps={{ native: true }}
              error={Boolean(touched.tipo_anuncio_id && errors.tipo_anuncio_id)}
              helperText={touched.tipo_anuncio_id && errors.tipo_anuncio_id}
              onBlur={handleBlur}
              value={values.tipo_anuncio_id}
              variant="outlined"
            >
              <option hidden
                value=''
              ></option>
              {tiposAnuncio_.map((tipo) => (
                <option
                  key={tipo.id}
                  value={tipo.id}
                >{tipo.nombre}</option>
              ))}
            </TextField>
          </Grid>
        );
      }
      return('');
  }

  function FieldSubtipoAnuncio(props){
      const handleBlur=props.propHandleBlur;
      const subtiposAnuncio_ = props.propSubtiposAnuncio;
      const handleChange=props.propHandleChange;
      const touched=props.propTouched;
      const errors=props.propErrors;
      const values=props.propValues;
      if (subtiposAnuncio_!==null) {
        return(
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Subtipo de anuncio"
              name="subtipo_anuncio_id"
              onChange={(e) => {handleChange(e);}}
              select
              SelectProps={{ native: true }}
              error={Boolean(touched.subtipo_anuncio_id && errors.subtipo_anuncio_id)}
              helperText={touched.subtipo_anuncio_id && errors.subtipo_anuncio_id}
              onBlur={handleBlur}
              value={values.subtipo_anuncio_id}
              variant="outlined"
            >
              <option hidden
                value=''
              ></option>
              {subtiposAnuncio_.map((sub) => (
                <option
                  key={sub.id}
                  value={sub.id}
                >{sub.nombre}</option>
              ))}
            </TextField>
          </Grid>
        );
      }
      return('');
  }

  function FieldEstado(props){
      const handleBlur=props.propHandleBlur;
      const handleChange=props.propHandleChange;
      const touched=props.propTouched;
      const errors=props.propErrors;
      const values=props.propValues;
      const estados_ = props.propEstados;
      if (estados_!==null) {
        return(
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Estado"
              name="estado_id"
              onChange={(e) => {handleChange(e);handleChangeGetMunicipios(e);}}
              select
              SelectProps={{ native: true }}
              error={Boolean(touched.estado_id && errors.estado_id)}
              helperText={touched.estado_id && errors.estado_id}
              onBlur={handleBlur}
              value={values.estado_id}
              variant="outlined"
            >
              <option hidden
                value=''
              ></option>
              {estados_.map((estado_) => (
                <option
                  key={estado_.id}
                  value={estado_.id}
                >{estado_.nombre}</option>
              ))}
            </TextField>
          </Grid>
        );
      }
      return('');
  }

  function FieldMunicipio(props){
      const handleBlur=props.propHandleBlur;
      const handleChange=props.propHandleChange;
      const touched=props.propTouched;
      const errors=props.propErrors;
      const values=props.propValues;
      const municipios_ = props.propMunicipios;
      if (municipios_!==null) {
        return(
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Municipio"
              name="municipio_id"
              onChange={(e) => {handleChange(e);}}
              select
              SelectProps={{ native: true }}
              error={Boolean(touched.municipio_id && errors.municipio_id)}
              helperText={touched.municipio_id && errors.municipio_id}
              onBlur={handleBlur}
              value={values.municipio_id}
              variant="outlined"
            >
              <option hidden
                value=''
              ></option>
              {municipios_.map((muni) => (
                <option
                  key={muni.id}
                  value={muni.id}
                >{muni.nombre}</option>
              ))}
            </TextField>
          </Grid>
        );
      }
      return('');
  }

  return (
    <React.Fragment>
      {add!==null ?
        <Formik
          initialValues={{
            titulo: add!==null ? add.titulo : '',
            descripcion_corta: add!==null ? add.descripcion_corta : '',
            descripcion_larga: add!==null ? add.descripcion_larga : '',

            colonia: add!==null && add.colonia!==null ? add.colonia : '',

            calle: add!==null && add.calle!==null ? add.calle : '',
            numero: add!==null && add.numero!==null ? add.numero : '',
            carretera: add!==null && add.carretera!==null ? add.carretera : '',
            kilometro: add!==null && add.kilometro!==null ? add.kilometro : '',

            largo: add!==null ? add.largo : '',
            ancho: add!==null ? add.ancho : '',
            latitud: add!==null ? add.latitud : '',
            longitud: add!==null ? add.longitud : '',
            target: add!==null ? add.target : '',
            concurrencia_diaria: add!==null ? add.concurrencia_diaria : '',
            tipo_anuncio_id: add!==null ? add.tipo_anuncio.id : '',
            subtipo_anuncio_id: add!==null ? add.subtipo_anuncio.id : '',
            estado_id: add!==null ? add.estado.id : '',
            municipio_id: add!==null ? add.municipio.id : '',
            enCarretera: (add.carretera===null || add.carretera==='') ? 'no' : 'si'
          }}
          validationSchema={Yup.object().shape({
            titulo: Yup.string().max(50).required(),
            descripcion_corta: Yup.string().max(250).required(),
            descripcion_larga: Yup.string().max(1000).required(),
            enCarretera: Yup.string(),
            calle: Yup
              .string()
              .max(100)
              .when('enCarretera', {
                is: 'no',
                then: Yup.string().required()
              }),
            numero: Yup
              .string()
              .max(7)
              .when('enCarretera', {
                is: 'no',
                then: Yup.string().required()
              }),
            colonia: Yup
              .string()
              .max(50)
              .when('enCarretera', {
                is: 'no',
                then: Yup.string().required()
              }),
            carretera: Yup
              .string()
              .max(100)
              .when('enCarretera', {
                is: 'si',
                then: Yup.string().required()
              }),
            kilometro: Yup
              .string()
              .max(5)
              .when('enCarretera', {
                is: 'si',
                then: Yup.string()
              }),
            largo: Yup.number().required().positive().integer().required(),
            ancho: Yup.number().required().positive().integer().required(),
            subtipo_anuncio_id: Yup.number().required(),
            municipio_id: Yup.number().required()
          })}
          onSubmit={(values) => {
            sellerServices.updateAdd(values,add.id)
            .then(response => {
            })
            .catch(function (error) {
                return error;
            })
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              autoComplete="off"
              onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}
              style={{
                display: 'inline-block',
                width: '100%',
              }}
            >
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      Informacion General
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Titulo"
                      name="titulo"
                      onChange={(e) => {handleChange(e); }}
                      error={Boolean(touched.titulo && errors.titulo)}
                      helperText={touched.titulo && errors.titulo}
                      onBlur={handleBlur}
                      value={values.titulo}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Descripción corta"
                      name="descripcion_corta"
                      onChange={(e) => {handleChange(e); }}
                      error={Boolean(touched.descripcion_corta && errors.descripcion_corta)}
                      helperText={touched.descripcion_corta && errors.descripcion_corta}
                      onBlur={handleBlur}
                      value={values.descripcion_corta}
                      variant="outlined"
                      multiline
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Descripción larga"
                      name="descripcion_larga"
                      onChange={(e) => {handleChange(e); }}
                      error={Boolean(touched.descripcion_larga && errors.descripcion_larga)}
                      helperText={touched.descripcion_larga && errors.descripcion_larga}
                      onBlur={handleBlur}
                      value={values.descripcion_larga}
                      variant="outlined"
                      multiline
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      Ubicación
                    </Typography>
                    <Divider />
                  </Grid>


                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="gender"
                        name="enCarretera"
                        onChange={(e) => {handleChange(e);}} 
                        value={values.enCarretera} 
                        error={Boolean(touched.enCarretera && errors.enCarretera)}
                        helperText={touched.enCarretera && errors.enCarretera}
                        onBlur={handleBlur}
                      >
                        <FormControlLabel value="si" control={<Radio color="primary" />} label="En carretera" />
                        <FormControlLabel value="no" control={<Radio color="primary" />} label="En zona urbana" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {values.enCarretera==='si' ?
                    <React.Fragment>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Carretera"
                          name="carretera"
                          onChange={(e) => {handleChange(e); }}
                          error={Boolean(touched.carretera && errors.carretera)}
                          helperText={touched.carretera && errors.carretera}
                          onBlur={handleBlur}
                          value={values.carretera}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Kilometro"
                          name="kilometro"
                          onChange={(e) => {handleChange(e); }}
                          error={Boolean(touched.kilometro && errors.kilometro)}
                          helperText={touched.kilometro && errors.kilometro}
                          onBlur={handleBlur}
                          value={values.kilometro}
                          variant="outlined"
                        />
                      </Grid>
                    </React.Fragment>
                  :
                    <React.Fragment>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Calle"
                          name="calle"
                          onChange={(e) => {handleChange(e); }}
                          error={Boolean(touched.calle && errors.calle)}
                          helperText={touched.calle && errors.calle}
                          onBlur={handleBlur}
                          value={values.calle}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Número exterior"
                          name="numero"
                          onChange={(e) => {handleChange(e); }}
                          type="number"
                          error={Boolean(touched.numero && errors.numero)}
                          helperText={touched.numero && errors.numero}
                          onBlur={handleBlur}
                          value={values.numero}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Colonia"
                          name="colonia"
                          onChange={(e) => {handleChange(e); }}
                          error={Boolean(touched.colonia && errors.colonia)}
                          helperText={touched.colonia && errors.colonia}
                          onBlur={handleBlur}
                          value={values.colonia}
                          variant="outlined"
                        />
                      </Grid>
                    </React.Fragment>
                  }
                  <FieldEstado
                    propEstados={estados}
                    propHandleChange={handleChange}
                    propTouched={touched}
                    propErrors={errors}
                    propHandleBlur={handleBlur}
                    propValues={values}
                  />
                  <FieldMunicipio
                    propMunicipios={municipios}
                    propHandleChange={handleChange}
                    propTouched={touched}
                    propErrors={errors}
                    propHandleBlur={handleBlur}
                    propValues={values}
                  />
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      Características
                    </Typography>
                    <Divider />
                  </Grid>
                  <FieldTipoAnuncio
                    propTiposAnuncio={tiposAnuncio}
                    propHandleChange={handleChange}
                    propTouched={touched}
                    propErrors={errors}
                    propHandleBlur={handleBlur}
                    propValues={values}
                  />
                  <FieldSubtipoAnuncio
                    propSubtiposAnuncio={subtiposAnuncio}
                    propHandleChange={handleChange}
                    propTouched={touched}
                    propErrors={errors}
                    propHandleBlur={handleBlur}
                    propValues={values}
                  />
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Largo (cm)"
                      name="largo"
                      onChange={(e) => {handleChange(e); }}
                      type="number"
                      error={Boolean(touched.largo && errors.largo)}
                      helperText={touched.largo && errors.largo}
                      onBlur={handleBlur}
                      value={values.largo}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Ancho (cm)"
                      name="ancho"
                      onChange={(e) => {handleChange(e); }}
                      type="number"
                      error={Boolean(touched.ancho && errors.ancho)}
                      helperText={touched.ancho && errors.ancho}
                      onBlur={handleBlur}
                      value={values.ancho}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Target"
                      name="target"
                      onChange={(e) => {handleChange(e); }}
                      select
                      SelectProps={{ native: true }}
                      value={values.target}
                      variant="outlined"
                    >
                      <option hidden
                        value=''
                      ></option>
                      {target.map((option) => (
                        <option
                          key={option}
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Concurrencia diaria"
                      name="concurrencia_diaria"
                      type="number"
                      onChange={(e) => {handleChange(e); }}
                      value={values.concurrencia_diaria}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Guardar
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
            </form>
          )}
        </Formik>
      :''}
    </React.Fragment>
  );
};

export default InformacionBasica;

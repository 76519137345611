import React, { useState, useEffect } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import {
  Row,
  Col,
  Button
} from "reactstrap";
import { publicServices } from '../../services/publicServices';
import image_default from '../../icons/image_default.svg';
import plus from '../../icons/plus.svg';

const LastAdds = () => {

  const [adds, setAdds] = useState(null);

  useEffect(() => {
    getLastAdds();
  }, []);

  function getLastAdds(){
    publicServices.lastAdds()
    .then(response => {
      if (response.status===200) {
        console.log('response.data.data==>');
        console.log(response.data.data);
        setAdds(response.data.data);
      }
    })
    .catch(function (error) {
        return error;
    })
  }

  function CardAdds(props){
    let adds_ = props.propAdds;
    var items = '';
    if (adds_!==null && adds_.length>0) {
      items = adds_.map(add => {
          let default_image='';
          add.imagenes.forEach(function(img) {
            if (img.principal===1) {
              default_image=img.url_thumb;
            }
          });
          if (default_image==='') {
            default_image=add.imagenes[0]!==undefined ? add.imagenes[0].url_thumb : image_default;
          }
          return(
            <Col xs="12" sm="6" md="4" className="mb-4 text-center" key={add.id}>
              <RouterLink to={'/anuncio/'+add.id} className="item_add">
                <img width="100%" src={default_image} alt={add.titulo} />
                <span className="overlay"></span>
                <span class="thumb-info-title">
                  <span class="thumb-info-inner">
                    {add.titulo}
                    <small className="block_ctrl">{add.municipio.nombre==add.estado.nombre?add.estado.nombre:add.municipio.nombre+' '+add.estado.nombre}</small>
                  </span>
                  <span class="thumb-info-type">{add.tipo_anuncio.nombre}</span>
                </span>
                <span class="thumb-info-action">
                  <span class="thumb-info-action-icon">
                    <img src={plus} alt=""/>
                  </span>
                </span>
              </RouterLink>
            </Col>
          )
        }
      );
    }
    return(
      <React.Fragment>
        <Row className="justify-content-center w-100">
          {items}
        </Row>
        <Row className="justify-content-center w-100">
          <Col xs="auto">
             <Button outline color="theme" to="/espacios-publicitarios" component={RouterLink} tag={RouterLink}>Ver más</Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  return (
      <CardAdds propAdds={adds}/>
  );
};

export default LastAdds;

import React from 'react';
import "../../../styles/scss/micro_titles.scss";

const TitleOne = (props) => {

  const p1=props.part_one;
  const p2=props.part_two;
  const line=props.line;

  return (
      <React.Fragment>
        <div className={line?'pb-3 title_one':'title_one'}>
          <h1>{p1}<span>{p2}</span></h1>
          {line?
            <div></div> 
          :''}
        </div>
      </React.Fragment>
  );
};

export default TitleOne;

import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import PublicLayout from 'src/layouts/PublicLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import OlvideContrasena from 'src/views/auth/OlvideContrasena';
import CambiaContrasena from 'src/views/auth/CambiaContrasena';
import CodeSent from 'src/views/auth/CodeSent';
import ValidarCode from 'src/views/auth/ValidarCode';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import RegistroPropietario from 'src/views/auth/RegistroPropietario';
import RegistroCliente from 'src/views/auth/RegistroCliente';
import SettingsView from 'src/views/settings/SettingsView';
import Anuncios from 'src/views/adds';
import Anuncio from 'src/views/add';
import Mapa from 'src/views/mapa';
import NuevoAnuncio from 'src/views/adds/add';
import CompletaVendedor from 'src/views/account/Complete/Vendedor';
import Home from 'src/views/public';
import EspaciosPublicitarios from 'src/views/public/EspaciosPublicitarios';
import AnuncioPublic from 'src/views/public/AnuncioPublic';
import Contacto from 'src/views/public/Contacto';
import Propietario from 'src/views/public/propietario/index.js';
import TerminosCondiciones from 'src/views/public/TerminosCondiciones';

import Caracteristicas from 'src/views/specs';

const routes = [
  {
    path: '/',
    element: <PublicLayout propSolid={false} propCliente={true}/>,
    children: [
      { path: '/', element: <Home /> },
      { path: 'home', element: <Home /> },
      { path: 'terminos-y-condiciones', element: <TerminosCondiciones /> }
    ]
  },
  {
    path: '/',
    element: <PublicLayout propSolid={true} propCliente={true}/>,
    children: [
      { path: 'anuncio/:id', element: <AnuncioPublic /> },
      { path: 'espacios-publicitarios', element: <EspaciosPublicitarios /> },
      { path: 'espacios-publicitarios/:tipo_p', element: <EspaciosPublicitarios /> },
      { path: 'contacto', element: <Contacto /> }
    ]
  },
  {
    path: '/',
    element: <PublicLayout propSolid={true} propCliente={false}/>,
    children: [
      { path: 'propietario', element: <Propietario /> },
      { path: 'propietario/contacto', element: <Contacto /> }
    ]
  },
  {
    path: '/vendor',
    element: <DashboardLayout propType='seller' />,
    children: [
      { path: 'anuncio/:id', element: <Anuncio /> },
      { path: 'anuncios', element: <Anuncios /> },
      { path: 'mapa', element: <Mapa /> },
      { path: '/anuncios/nuevo', element: <NuevoAnuncio /> },
      { path: 'mi-perfil', element: <AccountView propType='seller' /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'completa-tu-perfil', element: <CompletaVendedor /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/cliente',
    element: <DashboardLayout propType='cliente' />,
    children: [
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'mi-perfil', element: <AccountView propType='cliente' /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/a',
    element: <DashboardLayout propType='admin' />,
    children: [
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'mi-perfil', element: <AccountView propType='cliente' /> },
      { path: 'caracteristicas', element: <Caracteristicas /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'registro', element: <RegisterView /> },
      { path: 'registro-propietario', element: <RegistroPropietario /> },
      { path: 'registro-cliente', element: <RegistroCliente /> },
      { path: 'olvide-mi-contrasena', element: <OlvideContrasena /> },
      { path: 'cambia-tu-contrasena/:code', element: <CambiaContrasena /> },
      { path: 'codigo-enviado/:email', element: <CodeSent /> },
      { path: 'validar/:code', element: <ValidarCode /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import validator from 'validator';
import { publicServices } from '../../services/publicServices';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const RegistroCliente = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);

  return (
    <Page
      className={classes.root}
      title="Registro"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              name: '',
              password: '',
              type: 'cliente',
              repeat_password: '',
              policy: false
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email('El email no es valido').max(255).required('El email es requerido'),
                name: Yup.string().max(255).required('El nombre es requerido'),
                password: Yup.string().max(255).required('La contraseña es requerida').test('test-name', 'La contraseña no cumple las condiciones seguras', 
                  function(value) {
                    if (value!==undefined) {
                      if (validator.isStrongPassword(value, {
                        minLength: 8, minLowercase: 1,
                        minUppercase: 1, minNumbers: 1, minSymbols: 1
                      })) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  }),
                repeat_password: Yup.string().required('La contraseña es requerida').oneOf([Yup.ref('password'), null], 'La contraseña no coincide'),
                policy: Yup.boolean().oneOf([true], 'Debes aceptar los terminos y condiciones de uso')
              })
            }
            onSubmit={(values) => {
              setDisable(true);
              publicServices.createUser(values)
                .then(response => {
                  if (response.status === 201) {
                    navigate('/codigo-enviado/'+values.email, { replace: true });
                  }
                })
                .catch(function (error) {
                }).then(function () {
                  setDisable(false);
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Crea una cuenta nueva
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Y comienza a publicar tus espacios publicitarios
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Nombre"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  label="Contraseña"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                  helperText="Al menos 8 caracteres, 1 minuscula, 1 mayuscula, 1 numero y 1 simbolo."
                />
                <TextField
                  error={Boolean(touched.repeat_password && errors.repeat_password)}
                  fullWidth
                  helperText={touched.repeat_password && errors.repeat_password}
                  label="Repite la contraseña"
                  margin="normal"
                  name="repeat_password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.repeat_password}
                  variant="outlined"
                />
                <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Acepto los
                    {' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="/terminos-y-condiciones"
                      underline="always"
                      variant="h6"
                    >
                      Terminos y condiciones
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={disable}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Registrar
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Ya tienes una cuenta?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Entra aquí
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default RegistroCliente;
